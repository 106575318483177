import * as React from 'react';
import { Button, Grid, Box, Typography, Stack } from "@mui/material";
import "./Landing.scss";
import { useTranslation } from 'react-i18next';
import TopMenu from '../../../components/Menu/top-menu';
import userService from '../../../services/userService';
import Application from '../../../components/Application';
import jsUtils from '../../../utils/jsUtils';
import config from '../../../config';

export default function Landing({
  userInfo,
  onMenuItemClicked,
  showToastMessage,
  cssClass
}) {

  const { t } = useTranslation();
  const [htmlFormatSAMLIDP, setHtmlFormatSAMLIDP] = React.useState('');
  const [coyoteCssClass, setCoyoteCssClass] = React.useState(false);
  const navigateToUserManagement = () => {
    const userManagementUrl = userService.getUserManagementRouteUrl(localStorage.getItem('sid'), localStorage.getItem('timeOut'), global?.federatedType || null);
    window.location.assign(userManagementUrl);
  }

  const getName = (_userInfo) => {
    if (userInfo && (userInfo.firstName || userInfo.lastName)) {
      if (userInfo.firstName && userInfo.lastName) {
        return userInfo.firstName + " " + userInfo.lastName;
      }
      else {
        return userInfo.firstName || userInfo.lastName;
      }
    }
    else {
      return "";
    }
  }

  const navigateTo = (_url) => {
    let sid = localStorage.getItem('sid');
    const gmtOffset = jsUtils.getGMTOffset();
    if (sid && _url) {
      window.open(`${_url}?sid=${sid}&gmt_offset=${gmtOffset}`, "_blank", "noopener");

    }
  }
  // const navigateToQuickSight=(_url)=>{
  //   let sid=localStorage.getItem('sid');
  //   if(sid && _url){
  //     window.open(`${_url}`, "_blank", "noopener");

  //   }
  // }

  const navigateToNextGen = () => {
    let sid = localStorage.getItem('sid');
    window.location.assign(`${config.baseUrlMicrofrontend}/TimeEntry#sid=${sid}`);
  }
  const navigateToNewOpenWindow = () => {
    loadHTMLFormatSAMLIDP();
  }
  React.useEffect(()=>{
    console.log("userInfo", userInfo)
    if(userInfo && userInfo.groupsNames){
      let coyoteClassCac = userInfo.groupsNames.findIndex(group=>group === "App-CAC");
      let  coyoteClassNxtGen = userInfo.groupsNames.findIndex(group=>group === "App-NextGen");
      
      if( coyoteClassCac >-1 ||coyoteClassNxtGen >-1){
         setCoyoteCssClass("coyoteCssClassCover")
      }else{
        setCoyoteCssClass("landingPageCover");
      }
     
    }
  }, [userInfo])
  const getApplicationDetails = (_applicationGroup) => {
    switch (_applicationGroup?.id) {
      case "App-NextGen":
        return { imgSrc: "/CPMAttorneyWorkspace-icon.png", label: t('NEXTGEN_ICON_LABEL'), subText: t('TIME_ENTRY_CLIENT_ENQUIRY'), url: _applicationGroup?.url };
      case "App-LMS IV/V":
        return { imgSrc: "/LMS45-icons-073123.png", label: t('LMS_4_5_ICON_LABEL'), url: _applicationGroup?.url };
      case "App-CAC":
        return { imgSrc: "/CoyoteAnalyticsExclusive-icon.png", label: t('COYOTE_ANALYTICS'),subText: t('PRACTICE_MANAGEMENT_REPORTS'), url: _applicationGroup?.url };
      case "App-LMSPlus":
        return { imgSrc: "/LMSPlus-icons-073123.png", label: t('LMS_ICON_LABEL'), url: _applicationGroup?.url };
      case "App-QuickSight":
        return { imgSrc: "/mobile_icon_kpi_white_2.png", label: t('VIEW_KPI'), url: _applicationGroup?.url || 'https://quicksight.aws.amazon.com/' };
      default:
        return { imgSrc: null, label: null, url: null }
    }
  }

  const loadHTMLFormatSAMLIDP = () => {
    let myWindow = window.open("", "_blank");
    let dom = myWindow?.document;
    const endpoint = `/sso/v1/saml`;
    userService.getHtmlFormatSAMLIDP(endpoint).then((response) => {
      if (response?.data && !(response?.data instanceof Error) && response?.data?.data) {
        let htmlFormat = response?.data?.data ?? '';
        htmlFormat = htmlFormat.replace(/\'/g, "'").replace(/\"/g, '"').replace(/\r\t/g, "").replace(/\r\n/g, "").replace(/\n/g, "");
        // setHtmlFormatSAMLIDP(htmlFormat);
        // openWindow (htmlFormat);
        dom?.open();
        dom?.write(htmlFormat);
        dom?.close();

      } else if (response?.data && Number(response?.data.status) !== 200 && response.data.error.length > 0) {
        const errs = jsUtils.parseError(response.data.error);
        myWindow?.close();
        showToastMessage(errs);
      } else {
        myWindow?.close();
        showToastMessage(t('AN_UNEXPECTED_ERROR_HAS_OCCURRED'));
      }
    }).catch((ex) => {
      myWindow?.close();
      showToastMessage(t('AN_UNEXPECTED_ERROR_HAS_OCCURRED'));
    })
  }

  const navQuickSightHTMLFormatSAMLIDP = () => {
    let myWindow = window.open("", "_blank");
    let dom = myWindow?.document;
    const endpoint = `/sso/v1/saml?app=App-QuickSight`;
    userService.getQuickSightHtmlFormatSAMLIDP(endpoint).then((response) => {
      if (response?.data && !(response?.data instanceof Error) && response?.data?.data) {
        let htmlFormat = response?.data?.data ?? '';
        htmlFormat = htmlFormat.replace(/\'/g, "'").replace(/\"/g, '"').replace(/\r\t/g, "").replace(/\r\n/g, "").replace(/\n/g, "");
        // setHtmlFormatSAMLIDP(htmlFormat);
        // openWindow (htmlFormat);
        dom?.open();
        dom?.write(htmlFormat);
        dom?.close();

      } else if (response?.data && Number(response?.data.status) !== 200 && response.data.error.length > 0) {
        const errs = jsUtils.parseError(response.data.error);
        myWindow?.close();
        showToastMessage(errs);
      } else {
        myWindow?.close();
        showToastMessage(t('AN_UNEXPECTED_ERROR_HAS_OCCURRED'));
      }
    }).catch((ex) => {
      myWindow?.close();
      showToastMessage(t('AN_UNEXPECTED_ERROR_HAS_OCCURRED'));
    })
  }

  const renderApplications = (_userInfo) => {
    let parentContainer =""
    let applicationGroups = _userInfo?.apps || [];
    if(coyoteCssClass === "coyoteCssClassCover"){
      parentContainer="coyoteContainer"
    }
    if (applicationGroups.length) {
        return <Box sx={{ flexGrow: 1, }}>
          <Grid container
            direction="row"
            justifyContent="center"
            alignItems="baseline"
            className={parentContainer}
          >
            {
              applicationGroups.map((group) => {
                let { imgSrc, label, url, subText } = getApplicationDetails(group);
                return imgSrc && label && <Application key={imgSrc}
                  imgSrc={imgSrc}
                  label={label}
                  subText={subText}
                  url={url}
                  navigateTo={group?.id === 'App-LMS IV/V' || group?.id === 'App-CAC' ? navigateToNewOpenWindow :
                    (group?.id === 'App-NextGen' ? navigateToNextGen : group?.id === 'App-QuickSight' ? navQuickSightHTMLFormatSAMLIDP : navigateTo)}
                />
              })
              
            }
            
          </Grid>
          

        </Box>
    }
    else {
      return <></>
    }
  }

  return (
    <div className='dvContainer'>
      <TopMenu onMenuItemClicked={onMenuItemClicked}
        name={getName(userInfo)}
        email={userInfo?.email || ""}
        loginType={userInfo?.loginType} />
      
      <Box className={`${coyoteCssClass}`}>
         {coyoteCssClass ?  <img alt="" className='imgLogoSurepoint'  src="/SurePoint_Logo_Trademark.svg" /> : null}
          {coyoteCssClass === "coyoteCssClassCover" ? <div className='coyoteTitle'>{t('COYOTE_PRACTICE_MANAGEMENT')}</div>: null}
        <Box className="landingPageContainer">
              {renderApplications(userInfo)}
              
        </Box>  
             
        {userInfo && userInfo.groupsNames && userInfo.groupsNames.findIndex(group=>group === "Admins")!==-1 && <Stack direction="row"
            justifyContent="center"
            alignItems="center"  className='userManagementdv'>
          <Button data-testid="userMngtButton" onClick={() => { navigateToUserManagement() }} variant="text" sx={{ color: '#344054', fontWeight: 400, fontSize: 15, textTransform: 'none', '&:hover': { backgroundColor: 'transparent' } }}>
            <img alt="" className='toolManegement' src="/toolsettingicon.svg"/>
            <Typography className='landingColorText3'>&nbsp;&nbsp;&nbsp;{t('USER_MANAGEMENT')}</Typography>
          </Button>
        </Stack>}
      </Box>
    </div>
  );
}
