import React, { useState} from "react";
import "./SignIn.scss";
import { Button, Checkbox,CircularProgress, Link, Stack } from "@mui/material";
import { RESPONSE_STATUS, STATUS } from "../../../config/loginStatus";
import validator from "validator";
import CssTextField from "../../../components/CssTextField";
import authenticationService from "../../../services/authenticationService";
import { useTranslation } from 'react-i18next';
import StyledTooltip from "../../../components/common/StyledTooltip";

import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

export default function PassWordEntryScreen({onStatusChanged, setMfaDeviceList, showToastMessage, onEmailOrPhoneNumberChange,onLoginSuccess,flowId,email,setShowPasswordScreen,setFlowId}){

    const [password, setPassword] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const onClickShowPassword = () => setShowPassword((show) => !show);
    const onMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const { t } = useTranslation();

    const onPasswordChanged = (event) => {
        setPassword(event.target.value);
    }

    const onLoginClick = async(event) => {
        event.preventDefault();
        if (password) {
          setIsProcessing(true);
          await authenticationService.loginPasswordCheck(password,flowId)
            .then((response) => {
                if(response.data && !(response.data instanceof Error)){
                    setMfaDeviceList(response.data?.data?.devices ?? []);
                    localStorage.setItem('emaildeviceid',JSON.stringify(response.data?.data?.devices));
                    if( response.data?.data?.mfaStatus === RESPONSE_STATUS.COMPLETED){
                        onLoginSuccess(response.data?.data?.sid,response.data?.data?.timeOut);
                    }
                    else{
                        if(response.data?.data?.mfaStatus === RESPONSE_STATUS.OTP_REQUIRED){
                            localStorage.setItem('sid',response.data?.data?.sid);

                            if(response.data.data?.devices.findIndex((device)=>device.type==="EMAIL")!==-1){
                                localStorage.setItem('sid',response.data?.data?.sid);  

                                onStatusChanged(STATUS.MFA_CODE_ON_EMAIL_LOGIN);
                                onEmailOrPhoneNumberChange({ userEmail: email, userPassword: password});
                            }
                            else if(response.data.data?.devices.findIndex((device)=>device.type==="SMS")!==-1){

                                localStorage.setItem('sid',response.data?.data?.sid); 
                                onStatusChanged(STATUS.MFA_CODE_ON_PHONE_LOGIN)
                            }
                        }
                        else if(response.data?.data?.mfaStatus === RESPONSE_STATUS.DEVICE_SELECTION_REQUIRED){
                            localStorage.setItem('sid',response.data?.data?.sid);
                            onStatusChanged(STATUS.MFA_DEVICES_LIST_LOGIN);
                            onEmailOrPhoneNumberChange({ userEmail: email, userPassword: password});
                        }
                        else{
                            if(response.data?.error && Array.isArray(response.data?.error) && response.data?.error.findIndex((error)=>error.code === "ACCOUNT_LOCKED_OUT") !== -1){
                                showToastMessage(t('ACCOUNT_LOCKED_OUT'));
                                setTimeout(()=>{
                                    setShowPasswordScreen(false);
                                    setFlowId(null);
                                },2000)
                            }
                            else{
                                showToastMessage('Not a valid mfaStatus');
                            }
                        }
                    }

                }
                else{
                    if(response?.data?.response?.data?.status === 400){
                        showToastMessage(t("INCORRECT_USERNAME_OR_PASSWORD"))
                    }
                    else{
                        showToastMessage(t("AN_UNEXPECTED_ERROR_HAS_OCCURRED"));
                    }
                }
                setIsProcessing(false);
            })
            .catch((ex) => {
                showToastMessage && showToastMessage(t("AN_UNEXPECTED_ERROR_HAS_OCCURRED"));
                setIsProcessing(false);
            });
        }
    }

    return <form data-testid="form" autoComplete="on" onSubmit={onLoginClick}>
        <CssTextField
            value={password}
            required
            id="filled-password-input" 
            type={showPassword ? 'text' : 'password'}
            InputProps={{
            endAdornment: <InputAdornment position="end">
                <IconButton
                    aria-label="toggle password visibility"
                    onClick={onClickShowPassword}
                    data-testid="iconbutton"
                    onMouseDown={onMouseDownPassword}
                    edge="end"
                    sx={{color:'#0050C3'}}
                >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
                </InputAdornment>
            }}
            inputProps={{ "data-testid": "password" }}
            label={t('PASSWORD')} variant="outlined" fullWidth size="normal" focused
            className="passwordField"
            onChange={onPasswordChanged} />

        {/* <Stack
         direction="row"
         justifyContent="center"
         alignItems="center"
         marginBottom="1rem"
         className="rememberMeContainer"
       >
            <Checkbox size="medium" onChange={onChangeRememberMe}/>
            <span className="rememberMeLabel Roboto-font-500">{t('REMEMBER_ME')}</span>
        </Stack> */}
        <Stack
         direction="row"
         justifyContent="center"
         alignItems="center"
         marginBottom="1rem"
         className="forgotPasswordContainer"
       >
          <Link data-testid="forgotPasswordLink" href="#" underline="none" className="forgotPasswordLabel Roboto-font-500" onClick={() => onStatusChanged(STATUS.FORGOT_PASSWORD)}
          disabled={isProcessing}>{t('FORGOT_PASSWORD')}</Link>
       </Stack>
        <Button data-testid="button" type="submit" value="Submit" variant="contained" fullWidth className="signInButton" disabled={!password || isProcessing} >
            {isProcessing ? <CircularProgress sx={{p:'4px'}}/> : t('SIGN_IN')}
        </Button>
        </form>
    
}
