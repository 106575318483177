import React, { useState } from 'react';
import { Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const StyledTooltip = (props) => {
  const [showPoper, setShowPoper] = useState(false);

  return (
    <Tooltip open={showPoper} arrow {...props}
      componentsProps={{
        popper: {
          sx: {
            paddingLeft: '10px'
          }
        },
        arrow: {
          sx: {
            color: "#286fdb"
          }
        },
        tooltip: {
          sx: {
            color: "white",
            fontSize: '14px',
            backgroundColor: "#286fdb",
            fontWeight: 300,
            lineHeight: '24px',
            padding: '10px'
          }
        }
      }}>
      <InfoOutlinedIcon fontSize='medium' style={{ color: '#FF9800' }} onClick={() => setShowPoper(!showPoper)} 
              onMouseOver={() => setShowPoper(true)} onMouseOut={() => setShowPoper(false)} />
    </Tooltip>
  )
};

export default StyledTooltip;
