import { styled } from '@mui/material/styles';
import { Button } from "@mui/material";

const StyledButton = styled(Button)`
    text-transform: none;
    margin-top: 0.5rem; 
    font-size: 14px;
    line-height: 20px;
    height: 44px;  
    border-radius: 9px;
`;

export default StyledButton;