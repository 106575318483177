import React, { useState, useEffect} from "react";
import "./LandingPage.scss";
import Landing from "./Landing/Landing";
import UserProfileSetting from './UserProfileSetting/UserProfileSetting';
import UserResetPassword from './UserResetPassword/UserResetPassword';
import { PAGE_NAME, MENU_ITEM } from "../../config/menuItem";
import ToastMessage, {MESSAGE_TYPE} from "../../components/ToastMessage";
import userService from "../../services/userService";
import { useHistory   } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import jsUtils from "../../utils/jsUtils";
import LandingPageLoading from "../../components/common/LandingPageLoading";
import {env_name} from "../../config"

export default function LandingPage({setUserLoggedIn}) {

  const [currentPage, setCurrentPage] = useState(PAGE_NAME.LANDING);
  const [userInfo, setUserInfo] = useState({});
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(MESSAGE_TYPE.ERROR);
  const [isProcessing,setIsProcessing] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  useEffect(()=> {
    global.federatedType = null;
    document.title=t("LandingPageTitle");
    const endpoint = `/sso/v1/user`;
    setIsProcessing(true);
    userService.getUserProfile(endpoint).then((response) => {
      if (response?.data && !(response?.data instanceof Error)) {
        if (response.data?.data.loginType === 'EXTERNAL_AUTHENTICATION_REQUIRED'){
          global.federatedType = 'EXTERNAL_AUTHENTICATION_REQUIRED';
        }
        
        setUserInfo(response.data?.data);
      }
    }).catch(()=>{
      showToastMessage(t('AN_UNEXPECTED_ERROR_HAS_OCCURRED'));
    }).finally(()=>{
      //setTimeout(() => {
        setIsProcessing(false);
      //},[5000]);
      
    })
  }, []);

  useEffect(() => {
    if (userInfo.email) {
      const GroupId = userInfo.groupsNames.filter(
        (users) => !users.includes("App-") && !users.includes("Admins")
      );

      window?.pendo?.initialize({
        visitor: {
          id: userInfo.email, // logged-in user ID
          full_name: userInfo.firstName + " " + userInfo.lastName, // logged-in user name
          env: env_name,
          device: "web",
          email: userInfo.email, // logged-in user email
          // email:        // Recommended if using Pendo Feedback, or NPS Email
          // full_name:    // Recommended if using Pendo Feedback
          // role:         // Optional

          // You can add any additional visitor level key-values here,
          // as long as it's not one of the above reserved names.
        },

        account: {
          id: GroupId[0], // Required if using Pendo Feedback
          //     // name:         // Optional
          //     // is_paying:    // Recommended if using Pendo Feedback
          //     // monthly_value:// Recommended if using Pendo Feedback

          //     // You can add any additional account level key-values here,
          //     // as long as it's not one of the above reserved names.
        },
      });
    }
  }, [userInfo]);


  useEffect(()=>{
    const checkAndResetSessionTimeout=()=>{
      if(!localStorage.getItem('sid')){
        clearInterval(sessionStorage.getItem('timeoutId'));
        sessionStorage.clear();
        localStorage.clear();
        setUserLoggedIn(false);
      }
      else{
        let validTimeOutTime=Number(sessionStorage.getItem('timeOut')) || Number(localStorage.getItem('timeOut'));
        if(validTimeOutTime){
        clearInterval(sessionStorage.getItem('timeoutId'))
        let timeoutId=setInterval(()=>{
          onFocus();
        },2000);
        sessionStorage.setItem('timeoutId',timeoutId);
        }    
      }
      
    }
    window.addEventListener('storage', checkAndResetSessionTimeout)

    return () => {
      clearInterval(sessionStorage.getItem('timeoutId'))
      window.removeEventListener('storage', checkAndResetSessionTimeout)
    }
  },[]);

  const onFocus = () => {
    const timeStamp=sessionStorage.getItem('timeStamp');
    const timeOut=localStorage.getItem('timeOut') || sessionStorage.getItem('timeOut');
    if(timeStamp && timeOut && Number(timeStamp) && Number(timeOut)){
      let timeOutInMilliSeconds=Number(timeOut)*60*1000;
      let currentTimeStamp=Date.now();
      if((timeOutInMilliSeconds+Number(timeStamp)) < currentTimeStamp ){
        let activeTimer=sessionStorage.getItem('timeoutId');
        if(activeTimer){
          clearInterval(activeTimer);
        }
        try{
            handleLogout();
          }
          catch(error){
            console.log(error);
          }
          finally{
            localStorage.clear();
            sessionStorage.clear();
            setUserLoggedIn(false);
          }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    document.addEventListener("visibilitychange", onFocus);
    onFocus();
    return () => {
        window.removeEventListener("focus", onFocus);
        document.removeEventListener("visibilitychange", onFocus);
    };
  }, []);

  const updateUserInfo = (_userInfo) => {
    setUserInfo(_userInfo);
  }

  const showToastMessage = (message, type = MESSAGE_TYPE.ERROR) => {
    setMessage(message);
    setMessageType(type);
  }

  const handleClose = () => {
    setMessage('');
  }

  const handleLogout = () => {
    setIsProcessing(true);
    const endpoint = `/sso/v1/logout`;
    userService.signOut(endpoint).then((result) => {

      if (result?.data && !(result.data instanceof Error)) {
        //if (result.data.status === 200 && result.data.data) {
        if (result.data.status === 200) {
          clearInterval(sessionStorage.getItem('timeoutId'));
          localStorage.clear();
          sessionStorage.clear();
          setUserLoggedIn(false);
        }else if(result.data && Number(result.data.status)!==200 && result.data.error.length>0){
          const errs= jsUtils.parseError(result.data.error);
          showToastMessage(errs);
        }else{
          showToastMessage(t('AN_UNEXPECTED_ERROR_HAS_OCCURRED'));
        }
      }else{
        showToastMessage(t('AN_UNEXPECTED_ERROR_HAS_OCCURRED'));
      }
    }).catch((ex)=>{
      showToastMessage(t('AN_UNEXPECTED_ERROR_HAS_OCCURRED'));
    }).finally(() => {
      setIsProcessing(false);
    });

  };

  const onCancel = () => {
      setCurrentPage(PAGE_NAME.LANDING);
  }
  const onMenuItemClicked = (menuId) => {
    switch (menuId) {
      case MENU_ITEM.LOG_OUT:
        handleLogout();
        break;
      case MENU_ITEM.PROFILE_SETTINGS:
        setCurrentPage(PAGE_NAME.PROFILE_SETTINGS);
        break;
      case MENU_ITEM.RESET_PASSWORD:
        setCurrentPage(PAGE_NAME.RESET_PASSWORD);
        break;
    }
  }
  const renderPage = () => {
    if(isProcessing){
      return <LandingPageLoading />;
    }
    else {
      switch (currentPage) {
        case PAGE_NAME.PROFILE_SETTINGS:
          return <UserProfileSetting userInfo={userInfo} onMenuItemClicked={onMenuItemClicked} onCancel={onCancel} showToastMessage={showToastMessage} updateUserInfo={updateUserInfo}/>
        case PAGE_NAME.RESET_PASSWORD:
          return <UserResetPassword userInfo={userInfo} onMenuItemClicked={onMenuItemClicked} onCancel={onCancel} showToastMessage={showToastMessage}/>
        case PAGE_NAME.LANDING :
        default:
          return <Landing onMenuItemClicked={onMenuItemClicked} userInfo={userInfo} showToastMessage={showToastMessage}/>
      }
    }
    
  }

  return (<div>
        <ToastMessage message={message} type={messageType} onClose={handleClose} />
        {renderPage()}
  </div>);
}
