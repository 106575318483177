import React from 'react';
import {Avatar, Divider, Grid, IconButton, Menu, MenuItem, Typography} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Link from '@mui/material/Link';
import LockIcon from '@mui/icons-material/Lock';
import './top-menu.scss';
import { MENU_ITEM } from '../../config/menuItem';
import { useTranslation } from 'react-i18next';

export default function TopMenu({ email,name,onMenuItemClicked, backgroundColor ='#EAF4FF',loginType}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { t } = useTranslation();
    const topNavClicked = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    return (
        <Grid
            container
            className='topNavParent'
            direction="row-reverse"
            alignItems="center"
            style={{ backgroundColor: backgroundColor }}
            >
            <IconButton
                onClick={topNavClicked}
                data-testid="avatar-icon"
                className ="avatar-button">
                <div className='avatar'>{name}</div>
                <ExpandMoreIcon/>
            </IconButton>
            
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                data-testid='account-menu'
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <MenuItem>
                    <Grid item container direction="column">
                        <Typography mx={{color: '#1565C0'}}>{name}</Typography>
                        <Typography variant='body2' mx={{color: '#1565C0'}}>{email}</Typography>
                    </Grid>
                </MenuItem>
                {loginType !== "EXTERNAL_AUTHENTICATION_REQUIRED" && <Divider></Divider>}
                {loginType !== "EXTERNAL_AUTHENTICATION_REQUIRED" && <MenuItem data-testid="profileSettings" onClick={() => onMenuItemClicked(MENU_ITEM.PROFILE_SETTINGS)}>
                    <Grid container item alignItems='center'>
                        <AccountCircleIcon sx={{mr: '2rem', color: '#1565C0'}}></AccountCircleIcon>
                        <Typography variant="body2" mx={{color: '#1565C0'}}>{t('PROFILE_SETTINGS')}</Typography>
                    </Grid>
                </MenuItem>}
                {loginType !== "EXTERNAL_AUTHENTICATION_REQUIRED" && <MenuItem data-testid="resetPassword" onClick={() => onMenuItemClicked(MENU_ITEM.RESET_PASSWORD)}>
                    <Grid container item alignItems='center' >
                        <LockIcon sx={{mr: '2rem', color: '#1565C0'}}></LockIcon>
                        <Typography variant="body2" mx={{color: '#1565C0'}}>{t('RESET_PASSWORD')}</Typography>
                    </Grid>
                </MenuItem>}
                <Divider></Divider>
                <MenuItem data-testid="logoutMenu" onClick={() => onMenuItemClicked(MENU_ITEM.LOG_OUT)}>
                    <Typography variant="button" mx={{color: '#1565C0' , textTransform : 'none'}}>{t('SIGN_OUT')}</Typography>
                </MenuItem>
            </Menu>
        </Grid>
    )
}
