import React from "react";
import {
  Box,
  Stack
} from "@mui/material";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import TabletMacOutlinedIcon from "@mui/icons-material/TabletMacOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import { MFA_DEVICE_TYPE } from "../../../config/mfaTypes";
import mfaService from "../../../services/mfaService";
import CircularLoading from "../../../components/CircularLoading";

export default function MFADevicesList({
  mfaDeviceList,
  isLoading,
  onDeviceSelection
}) { 

  const getMfaIcon =(type) => {
    switch (type) {
      case MFA_DEVICE_TYPE.EMAIL:
        return <MailOutlineOutlinedIcon color="primary" fontSize="large" sx={{ color: 'rgba(45, 160, 235, 1)', width: "40px" }} />;
      case MFA_DEVICE_TYPE.SMS:
        return <ChatOutlinedIcon color="primary" fontSize="large" sx={{ color: 'rgba(45, 160, 235, 1)', width: "40px" }} />;
      case MFA_DEVICE_TYPE.VOICE:
        return <PhoneInTalkIcon color="primary" fontSize="large" sx={{ color: 'rgba(45, 160, 235, 1)', width: "40px" }} />;
      case MFA_DEVICE_TYPE.TOTP:
        return <TabletMacOutlinedIcon color="primary" fontSize="large" sx={{ color: 'rgba(45, 160, 235, 1)', width: "40px" }} />;
      default:
        return <TabletMacOutlinedIcon color="primary" fontSize="large" sx={{ color: 'rgba(45, 160, 235, 1)', width: "40px" }} />;
    }
  }

  const listRegisteredMFAMethod = (item, index, ar) => {
    return (
        <ListItem
          disablePadding
          onClick={() => null}
          key={"device" + index}
        >
          <ListItemButton data-testid="deviceSelectionClick" onClick={() => onDeviceSelection(item)}>
            <ListItemIcon>
              {getMfaIcon(item.type)}
            </ListItemIcon>
            <ListItemText
              primary={mfaService.getMfaTypeName(item.type)}
              secondary={
                <span className="RemoveMFAMethodPopupLable">
                  {item.email || item.phone || 'Microsoft Authenticator'}
                </span>
              }
            />          
          </ListItemButton>
        </ListItem>
      );    
  }
  return (
    <Box className="mfa-layout">
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            ><img alt="" style={{ minWidth:"18rem" , width: "100%", padding: "2rem 1rem 2rem 1rem" }} src="/SurePoint_Logo_Trademark.svg" />
        </Stack>		
				 <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            marginBottom="10px"
          >
            <span className="RemoveMFAMethodPopupHeader">
              Select an authentication method to use to sign in.
            </span>
          </Stack>
          {isLoading ? <CircularLoading />:
            <List sx={{width:'100%'}}>
                {mfaDeviceList &&
                  mfaDeviceList.length > 0 &&
                  mfaDeviceList.map(listRegisteredMFAMethod)}
            </List>
          }
          <div style={{ height: "20px" }}></div>          
    </Box>   
  );
}
