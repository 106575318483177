import React, { useState } from "react";
import { Box, Button, Modal, Stack, Typography, CircularProgress, useMediaQuery } from "@mui/material";
import CssTextField from "../../../components/CssTextField";
import Link from '@mui/material/Link';
import { STATUS } from "../../../config/loginStatus";
import { useTranslation } from "react-i18next";
import validator from "validator";
import "./MfaScreens.scss";
import jsUtils from "../../../utils/jsUtils";
import mfaService from "../../../services/mfaService";
import { MFA_DEVICE_TYPE, MFA_STATUS } from "../../../config/mfaTypes";
import CrossIcon from "../../../components/common/CrossIcon";
import StyledTooltip from "../../../components/common/StyledTooltip";

export default function AuthenticationInput({ onStatusChanged, authMethod, onEmailOrPhoneNumberChange, userInfo, showToastMessage }) {

    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState('');
    const [openModal, setOpenModal] = useState(true);
    const { t } = useTranslation();
    const [isProcessing, setIsProcessing] = useState(false);
    const forMobileDevice = useMediaQuery('(max-width: 450px)');
    
    const onContinueEmail = (event) => {
        event.preventDefault();
        setIsProcessing(true)
        mfaService.requestAddNewMfaMethod(MFA_DEVICE_TYPE.EMAIL, email).then((result) => {
            if (result && !(result instanceof Error) && result?.data?.status === MFA_STATUS.ACTIVATION_REQUIRED) {
                onEmailOrPhoneNumberChange({
                    id: result?.data.id,
                    type: result?.data.type,
                    status: result?.data.status,
                    phone: result?.data.phone,
                });
                onStatusChanged(STATUS.MFA_CODE_ON_EMAIL);
            } else if (result?.response?.data) {
                parseError(result?.response?.data);
            } else {
                showToastMessage(t('AN_UNEXPECTED_ERROR_HAS_OCCURRED'));
            }
        }).catch((ex) => {
            showToastMessage(t('AN_UNEXPECTED_ERROR_HAS_OCCURRED'));
        }).finally(() => {
            setIsProcessing(false);
        })
    }


    const parseError = (err) => {
        let errorMsg = '';
        const errorCode = err.error[0]?.code;

        if (errorCode === 'INVALID_DATA') {
            const detailError = err.error[0]?.details[0];
            errorMsg = t('INVALID_PHONE_NUMBER'); //detailError?.message ?? t('AN_UNEXPECTED_ERROR_HAS_OCCURRED');
        } else {
            // Edge case where the error detail not INVALID_VALUE. Should never happen unless an API change happens without updating the UI.
            errorMsg = t('AN_UNEXPECTED_ERROR_HAS_OCCURRED');
        }
        showToastMessage(errorMsg);
    }

    const onContinuePhoneNumber = (event) => {
        event.preventDefault();
        let phone = phoneNumber.replaceAll('-','');
        if (!phone.startsWith('+')) {
            phone = '+1' + phone;
        }
        setIsProcessing(true)
        mfaService.requestAddNewMfaMethod(MFA_DEVICE_TYPE.SMS, phone).then((result) => {
            if (result && !(result instanceof Error) && result?.data.status === MFA_STATUS.ACTIVATION_REQUIRED) {
                onEmailOrPhoneNumberChange({
                    id: result?.data.id,
                    type: result?.data.type,
                    status: result?.data.status,
                    phone: result?.data.phone,
                });
                onStatusChanged(STATUS.MFA_CODE_ON_PHONE);
            } else if (result?.response?.data) {
                parseError(result?.response?.data);
            } else {
                showToastMessage(t('AN_UNEXPECTED_ERROR_HAS_OCCURRED'));
            }
        }).catch((ex) => {
            showToastMessage(t('AN_UNEXPECTED_ERROR_HAS_OCCURRED'));
        }).finally(() => {
            setIsProcessing(false);
        })
    }

    const onEmailChanged = (event) => {
        setEmail(event.target.value);
    }

    const onPhoneNumberChange = (event) => {
        const numPhone = jsUtils.phoneNumberFormat(event.target.value);
        setPhoneNumber(numPhone);
    }
    const onCloseModel = () =>{
        setOpenModal(false)
        onStatusChanged(STATUS.MANAGE_MFA_METHODS);
    }
    const isPhoneNumber = (value) => {
        // Simple check if value only contains 10 digits
        const phone = value.replaceAll('-', '');
        return validator.isNumeric(phone) && phone.length === 10;
    }
    const typoStyle = {
        fontFamily: "Roboto",
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        textAlighn: 'center'
    };

    if (authMethod === STATUS.AUTHENTICATION_THROUGH_EMAIL) {
        return <>
            <Modal
                open={openModal}
                onClose={onCloseModel}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                className="popup"
            >
                <form onSubmit={onContinueEmail} >
                <Box className="mfacode-input-box">
                    <Stack
                        direction="row"
                        justifyContent="right"
                        alignItems="right"
                        ><CrossIcon fontSize='small' onClose={onCloseModel} /></Stack>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        marginBottom="20px"
                    >
                        <Typography sx={{ ...typoStyle }}>{t('ENTER_YOUR_EMAIL_FOR_AUTHENTICATION')}</Typography>
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CssTextField
                            required
                            value={email}
                            type="email"
                            id="outlined-basic" label={t('EMAIL')} variant="outlined" fullWidth size="normal" focused autoFocus={true}
                            onChange={onEmailChanged}
                            InputProps={{
                                endAdornment: email && !validator.isEmail(email) && 
                                    <StyledTooltip title={t('INVALID_EMAIL_FORMAT')} placement={forMobileDevice ? "bottom" : "right" } />
                            }}
                            inputProps={{'data-testid':"email"}}
                        />
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Button type='submit' variant="contained" fullWidth className="continueButton" disabled={!email} >{
                            isProcessing ? <CircularProgress size={'24px'} sx={
                                { p: '4px' }
                            } /> : t('CONTINUE')
                        } </Button>
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Link href="#" underline="always" onClick={onCloseModel}>{t('CANCEL')}</Link>
                    </Stack>
                </Box>
                </form>
            </Modal>
        </>
    }
    else if (authMethod === STATUS.AUTHENTICATION_THROUGH_SMS) {
        return <>
            <Modal
                open={openModal}
                onClose={onCloseModel}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                className="popup"
            >
                <form onSubmit={onContinuePhoneNumber} >
                    <Box className="mfacode-input-box">
                        <Stack
                            direction="row"
                            justifyContent="right"
                            alignItems="right"
                        ><CrossIcon fontSize='small' onClose={onCloseModel} /></Stack>
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            marginBottom="20px"
                        >
                            <Typography sx={{ ...typoStyle }}> {t('ENTER_YOUR_PHONE_NUMBER_FOR_AUTHENTICATION')}</Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <CssTextField
                                value={phoneNumber}
                                required
                                type="tel"
                                inputProps={{
                                    readOnly: isProcessing,
                                    maxLength: 12,
                                    "data-testid":"phone"
                                }}
                                id="outlined-basic" label={t('Phone Number')} variant="outlined" fullWidth size="normal" focused autoFocus={true}
                                onChange={onPhoneNumberChange}

                                InputProps={{
                                    endAdornment: phoneNumber && !isPhoneNumber(phoneNumber) && 
                                        <StyledTooltip title={t('INVALID_PHONE_NUMBER')} placement={forMobileDevice ? "bottom" : "right" } />
                                }}
                            />
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Button type='submit' variant="contained" fullWidth className="continueButton" 
                            disabled={((!phoneNumber || !!phoneNumber) && !isPhoneNumber(phoneNumber)) || isProcessing } 
                            >
                                {
                                    isProcessing ? <CircularProgress size={'24px'} sx={
                                        { p: '4px' }
                                    } /> : t('CONTINUE')
                                } </Button>
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"

                        >
                            <Link href="#" underline="always" onClick={onCloseModel}>{t('CANCEL')}</Link>
                        </Stack>
                    </Box>
                </form>
            </Modal>
        </>
    }
}
