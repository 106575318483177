import { Box } from "@mui/material";
import React from "react";
import QRCode from "react-qr-code";

const QRCodeComponent = ({ value, size = 456, maxWidth= 156, viewBox='0 0 456 456' }) => {

    return <Box sx={{ height: "auto", margin: "0 auto", maxWidth: maxWidth, width: "100%" }}>
        <QRCode
            size={size}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={value}
            viewBox={viewBox}
        />
    </Box>
}

export default QRCodeComponent;