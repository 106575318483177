import React from 'react';
import './sassStyles/global.scss';
import { createRoot } from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import './config/i18n/i18n_config';
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  // <React.StrictMode>
    <BrowserRouter>
    <StyledEngineProvider injectFirst>
      <App/>
    </StyledEngineProvider>
    </BrowserRouter>
  // </React.StrictMode>
);
