import React, { useEffect, useState } from "react";
import "./MfaScreens.scss";
import { Button, Box, Grid, Modal, Stack, Typography, CircularProgress } from "@mui/material";
import CssTextField from "../../../components/CssTextField";
import Link from '@mui/material/Link';
import { STATUS } from "../../../config/loginStatus";
import QRCodeComponent from "../../../components/common/QRCodeComponent";
import { useTranslation } from "react-i18next";
import mfaService from "../../../services/mfaService";
import { MFA_DEVICE_TYPE, MFA_STATUS } from "../../../config/mfaTypes";
import CircularLoading from "../../../components/CircularLoading";
import { MESSAGE_TYPE } from "../../../components/ToastMessage";
import CrossIcon from "../../../components/common/CrossIcon";

export default function MfaSignInCode({
    onStatusChanged,
    onAddAuthenticationMethod,
    authMethod,
    showToastMessage,
    userInfo,
}) {

    const [code, setCode] = useState("");
    const [authKey, setAuthKey] = useState('');
    const [openAlert, setOpenAlert] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isResending, setIsResending] = useState(false);
    const [secret, setSecret] = useState('');
    const [deviceId, setDeviceId] = useState();
    const { t } = useTranslation();

    useEffect(() => {
        if (authMethod === STATUS.AUTHENTICATION_THROUGH_APPQR) {
            setIsLoading(true);
            mfaService.requestAddNewMfaMethod(MFA_DEVICE_TYPE.TOTP).then((response) => {
                if (response && !(response instanceof Error) && response?.data?.status === MFA_STATUS.ACTIVATION_REQUIRED) {
                    setSecret(response?.data?.secret);
                    setDeviceId(response?.data?.id);
                    setAuthKey(mfaService.generateTotpUri(userInfo.email, response?.data?.secret));
                }
            }).catch((ex) => {
                showToastMessage('Failed to add authenticator app.');
                goBack();
            }).finally(() => setIsLoading(false));
        } else {
            setDeviceId(userInfo?.mfaDevice?.id);
        }
    },[])

    const onCodeChanged = (event) => {
        setCode(event.target.value)
    }
    const styleAuthKey = (key) => {
        let chars = key.split("");
        let strings = [];
        let chunk = '';
        let index = 0;
        while (index < key.length) {
            for (let i = 0; i < 4; i++) {
                chunk = chunk + (chars[i + index] || "");
            }
            index = index + 4;
            strings = [...strings, chunk];
            chunk = "";
        }

        let blueSpans = "";
        let finalSpans = "";
        strings.forEach((string, i) => {
            if (i % 2 === 0) {
                blueSpans = blueSpans + "<span class=\"blue-text\">" + string + "</span>";
                finalSpans = finalSpans + blueSpans;
                blueSpans = "";
            } else {
                finalSpans = finalSpans + string;
            }
        });

        return finalSpans;
    }

    const goBack = () => {
        setOpenAlert(false);
        onStatusChanged(STATUS.MANAGE_MFA_METHODS);
    }

    const getMessage = () => {
        switch(authMethod) {
            case STATUS.AUTHENTICATION_THROUGH_APPQR:
                return t('AUTHENTICATOR_APP_ADDED');
            case STATUS.AUTHENTICATION_THROUGH_SMS:
                return t('SMS_AUTHENTICATION_ADDED');
            case STATUS.AUTHENTICATION_THROUGH_EMAIL:
                return t('EMAIL_AUTHENTICATION_APP_ADDED');
            default :
                return 'MFA Authentication is added.';
        }
    }

    const parseError = (err) => {
        let errorMsg = '';
        const errorCode = err.error[0]?.code;

        if (errorCode === 'INVALID_DATA') {
            const detailError = err.error[0]?.details[0];
            if (detailError && detailError?.code === 'INVALID_OTP') {
                const errorTarget = detailError.target;
                if (errorTarget === 'otp') {
                    errorMsg = t('MFA_CODE_INVALID_OR_EXPIRED');
                } else {
                    errorMsg = detailError.message ?? t('AN_UNEXPECTED_ERROR_HAS_OCCURRED');
                }
            } else {
                // Edge case where the error detail is INVALID_VALUE, but it's not a value we expect. Should never happen unless an API change happens without updating the UI.
                errorMsg = t('AN_UNEXPECTED_ERROR_HAS_OCCURRED');
            }
        } else {
            // Edge case where the error detail not INVALID_VALUE. Should never happen unless an API change happens without updating the UI.
            errorMsg = t('AN_UNEXPECTED_ERROR_HAS_OCCURRED');
        }
        showToastMessage(errorMsg);
    }

    const getType = () => {
        if (authMethod === STATUS.AUTHENTICATION_THROUGH_APPQR) {
            return MFA_DEVICE_TYPE.TOTP;
        } else if (authMethod === STATUS.AUTHENTICATION_THROUGH_SMS) {
            return MFA_DEVICE_TYPE.SMS;
        } else if (authMethod === STATUS.AUTHENTICATION_THROUGH_EMAIL) {
            return MFA_DEVICE_TYPE.EMAIL;
        } else {
            return MFA_DEVICE_TYPE.PLATFORM;
        }
    }

    const authenticateTotp = (event) => {
        event.preventDefault();
        setIsLoading(true);
        const type = getType();
        mfaService.confirmAddMfaMethod(deviceId, type, code).then(result => {
            console.log('confirmAddMfaMethod: ', result);
            if (result && !(result instanceof Error)) {
                onAddAuthenticationMethod(result);
                onStatusChanged(STATUS.MANAGE_MFA_METHODS);
                showToastMessage(getMessage(), MESSAGE_TYPE.SUCCESS);
            } else if (result?.response?.data) {
                parseError(result?.response?.data);
            } else {
                showToastMessage(t('AN_UNEXPECTED_ERROR_HAS_OCCURRED'));
            }
        })
        .catch((ex) => {
            showToastMessage(t('AN_UNEXPECTED_ERROR_HAS_OCCURRED'));
        })
        .finally(() => setIsLoading(false));
    }

    const resendSmsCode = () => {
        setIsResending(true);
        mfaService.deleteMfaUserDevice(deviceId);
        mfaService.requestAddNewMfaMethod(MFA_DEVICE_TYPE.SMS, userInfo.mfaDevice?.phone).then((result) => {
            if (result && !(result instanceof Error) && result?.data?.status === MFA_STATUS.ACTIVATION_REQUIRED) {
                setDeviceId(result?.data?.id);
            } else if (result?.response?.data) {
                parseError(result?.response?.data);
            } else {
                showToastMessage(t('AN_UNEXPECTED_ERROR_HAS_OCCURRED'));
            }
        }).catch((ex) => {
            showToastMessage(t('AN_UNEXPECTED_ERROR_HAS_OCCURRED'));
        }).finally(() => {
            setIsResending(false);
        })
    }

    if (isLoading) {
        return <div className="mfaContainer"> <CircularLoading /></div>
    } else if (authMethod === STATUS.AUTHENTICATION_THROUGH_APPQR) {
        return <div className="mfaContainer">
            <Modal
                open={openAlert}
                onClose={goBack}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                className="popup"
            >
                <form onSubmit={authenticateTotp} >
                    <Box className="mfacode-input-box-appqr">
                        <Grid container spacing={4}
                            direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item xs={12} md={12} >
                                <Stack
                                    direction="row"
                                    justifyContent="right"
                                    alignItems="right"
                                    marginTop="-0.8rem"
                                    zIndex={1000}
                                ><CrossIcon fontSize='small' onClose={goBack} /></Stack>
                            </Grid>
                            <Grid item xs={12} md={12} >
                                <Typography className="appqr-pop-up-title"> {t('AUTHENTICATION_THROUGH_APPQR_HEADER_LABEL')}</Typography>
                            </Grid>
                            <Grid item xs={12} md={12} >
                                <QRCodeComponent value={authKey} />
                            </Grid>
                            <Grid item xs={12} md={12} >
                                <div><span className="auth-key-label">{t('KEY')}</span></div>
                                <div><span className="auth-key" dangerouslySetInnerHTML={{ __html: styleAuthKey(secret) }}></span></div>
                            </Grid>
                            <Grid item xs={12} md={12} >
                                <CssTextField inputProps={{
                                        autoComplete: 'off',
                                        form: {
                                            autoComplete: 'off',
                                        },
                                        "data-testid":"codeqr"
                                    }}
                                    required
                                    value={code}
                                    id="outlined-basic" label={t('CODE')} variant="outlined" fullWidth size="normal" focused
                                    onChange={onCodeChanged} />

                                <Button type='submit' variant="contained" fullWidth className="continueButton" disabled={!code} >{t('CONTINUE')}</Button>
                                
                                <Stack
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Link href="#" className="appqr-link" underline="always" onClick={goBack}>{t('CANCEL')}</Link></Stack>
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            </Modal>
        </div>
    } else if (authMethod === STATUS.AUTHENTICATION_THROUGH_SMS) {
        return <div className="mfaContainer">
            <Modal
                open={openAlert}
                onClose={goBack}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                className="popup"
            >
                <form onSubmit={authenticateTotp}>
                    <Box className="mfacode-input-box">
                        <Stack
                            direction="row"
                            justifyContent="right"
                            alignItems="right"
                        ><CrossIcon fontSize='small' onClose={goBack} /></Stack>
                        <Typography className="mfaLabel"> {t('ENTER_THE_CODE_RECEIVED_TO_COMPLETE_SMS_PAIRING')}</Typography>
                        <CssTextField inputProps={{
                                autoComplete: 'off',
                                form: {
                                    autoComplete: 'off',
                                },
                                "data-testid":"smsCode"
                            }}
                            required
                            value={code}
                            id="outlined-basic" label={t('CODE')} variant="outlined" fullWidth size="normal" focused
                            onChange={onCodeChanged} />
                        <Button type='submit' variant="contained" fullWidth className="continueButton" disabled={!code} >
                            {isResending
                                ? <CircularProgress size={'24px'} sx={{ p: '4px' }} />
                                : t('CONTINUE')
                            }</Button>
                        <Typography className="mfaLabel"><Link href="#" underline="none" onClick={resendSmsCode}>{t('RESEND_CODE')}</Link></Typography>
                    </Box>
                </form>
            </Modal>
        </div>
    } else if (authMethod === STATUS.AUTHENTICATION_THROUGH_EMAIL) {
        return <div className="mfaContainer">
            <div className="mfaLabel Roboto-font-400"><span>{t('ENTER_THE_MFA_CODE_SENT_TO_EMAIL')}</span></div>
            <CssTextField inputProps={{
                    autoComplete: 'off',
                    form: {
                        autoComplete: 'off',
                    },
                    "data-testid":"email"
                }}
                required
                value={code}
                id="outlined-basic" label={t('MFA_CODE')} variant="outlined" fullWidth size="normal" focused
                onChange={onCodeChanged} />
            <Button variant="contained" fullWidth className="continueButton" disabled={!code} onClick={authenticateTotp}>{t('CONTINUE')}</Button>
            <div className="mfaLabel Roboto-font-400"><Link href="#" underline="none">{t('RESEND_CODE')}</Link></div>
            <div className="mfaLabel Roboto-font-400"><Link href="#" underline="always" onClick={() => onStatusChanged(STATUS.SELECT_AUTHENTICATION_METHOD)}>{t('TRY_ANOTHER_METHOD')}</Link></div>
        </div>
    }
}
