import React, { useEffect, useState } from "react";
import "./UserResetPassword.scss";
import _ from "lodash";
import {
  Button,
  Grid,
  Box,
  Stack,
  CircularProgress,
  useMediaQuery
} from "@mui/material";

import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import TextFieldComponent from "../../../components/TextFieldComponent";
import {useTranslation} from 'react-i18next';
import config from "../../../config";
import { defaultPasswordPolicy, passwordRequirementsValidator, getServerValidatedRequirementMessage } from "../../../utils/passwordValidate";
import userService from "../../../services/userService";
import { MESSAGE_TYPE } from "../../../components/ToastMessage";
import TopMenu from "../../../components/Menu/top-menu";
import StyledTooltip from "../../../components/common/StyledTooltip";
import PasswordPolicy from "../../../components/common/PasswordPolicy";

export default function UserResetPassword(props) {
  const {
    isAdmin = false,
    onMenuItemClicked,
    userInfo,
  } = props;

  const {t} = useTranslation();
  const [isProcessing, setIsProcessing] = useState(false);

  const [currentPassWord, setCurrentPassword] = useState("");
  const [newPassWord, setNewPassWord] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [incorrectCurrentPassword, setIncorrectCurrentPassword] = useState(false);
  const [textFieldStyle, setTextFieldStyle] = useState({maxWidth:665, marginLeft:15});

  const forMobileDevice = useMediaQuery('(max-width: 400px)');

  useEffect(() => {
    if(forMobileDevice)
    setTextFieldStyle({...textFieldStyle, maxWidth: '220px', marginLeft: '25px' });
    else
    setTextFieldStyle({...textFieldStyle, maxWidth: '665px', marginLeft: '15px' })
  },[forMobileDevice]);

  //show password
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const onClickShowCurrentPassword = () => setShowCurrentPassword((show) => !show);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const onClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const onClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
  const onMouseDownPassword = (event) => {
      event.preventDefault();
  };
  //end showpassword
  useEffect(()=>{
    document.title=t("RESET_PASSWORD_TITLE");
    return ()=>{
      document.title=t("LandingPageTitle");
    }
  },[])

  const onCurrentPasswordChanged = (event) => {
    setCurrentPassword(event.target.value);
  }

  const onNewPassWordChanged = (event) => {
    setNewPassWord(event.target.value);
  }
  const onConfirmNewPasswordChanged = (event) => {
    setConfirmNewPassword(event.target.value);
  }

  const showToastMessage = (message, type) => {
    props.showToastMessage && props.showToastMessage(message, type);
  }

  const cancelSave = () => {
    props.onCancel && props.onCancel();
  }

  const parseError = (err) => {
    let errorMsg = '';
    const errorCode = err.error[0]?.code;

    if (errorCode === 'INVALID_DATA') {
      const detailError = err.error[0]?.details[0];
      if (detailError && detailError?.code === 'INVALID_VALUE') {
        const errorTarget = detailError.target;
        if (errorTarget === 'currentPassword') {
          setIncorrectCurrentPassword(true);
        } else if (errorTarget === 'recoveryCode') {
          errorMsg = t('INVALID_AUTHENTICATION_CODE');
        } else if (errorTarget === 'newPassword') {
          const unsatisfiedServerRequirements = detailError.innerError?.unsatisfiedRequirements;
          if (unsatisfiedServerRequirements) {
            // if there are multiple server validation fails, show just one
            const failedReq = unsatisfiedServerRequirements[0];
            errorMsg = getServerValidatedRequirementMessage(failedReq, defaultPasswordPolicy, t);
          } else {
            errorMsg = detailError.message ?? t('AN_UNEXPECTED_ERROR_HAS_OCCURRED');
          }
        } else {
          // Edge case where the error detail is INVALID_VALUE, but it's not a value we expect. Should never happen unless an API change happens without updating the UI.
          errorMsg = t('AN_UNEXPECTED_ERROR_HAS_OCCURRED');
        }
      }
    } else {
      // Edge case where the error detail not INVALID_VALUE. Should never happen unless an API change happens without updating the UI.
      errorMsg = t('AN_UNEXPECTED_ERROR_HAS_OCCURRED');
    }
    if (errorMsg) {
      showToastMessage(errorMsg);
    }
  }


  const changePassword = (event) => {
    event.preventDefault();
    const endPoint = `/sso/v1/user/password`;
    setIsProcessing(true);   
    userService.changePassword(config.ssoApiBaseUrl, endPoint, currentPassWord, newPassWord).then((response) => {
      if (response?.data && ! (response.data instanceof Error) && response.data?.status===200) {
          showToastMessage(t('PASSWORD_CHANGED'), MESSAGE_TYPE.SUCCESS);
          cancelSave();
      } else if (response?.data?.response?.data) {
        parseError(response?.data?.response?.data);
      } else {
        showToastMessage(t('AN_UNEXPECTED_ERROR_HAS_OCCURRED'));
      }
    }).catch(ex => {
      console.warn('changePassword: ', {...ex});
      showToastMessage(t('AN_UNEXPECTED_ERROR_HAS_OCCURRED'));
    }).finally(() => {
      setIsProcessing(false);
    });
  }

  const styles = {
    input: {
      height: 42
    }
  }

  const clientValidatedRequirements = passwordRequirementsValidator(defaultPasswordPolicy, newPassWord);
  const requirementsMet = !!newPassWord && _.reduce(clientValidatedRequirements, (result, req) => result && req.isValid, true);
  const doPasswordsDiffer =  confirmNewPassword && !_.isEqual(newPassWord, confirmNewPassword);

  const getPasswordPolicyList =  <div>
    <ul className="bullet-points"> Your new password must:
      <li>Be at least 8 characters long</li>
      <li>Contain at least 1 uppercase letter</li>
      <li>Contain at least 1 number</li>
      <li>Contain at least 1 special character</li>
    </ul>
  </div>;

  return (
    <div className="dvresetPassContainerMain">
      <TopMenu backgroundColor= '#fff'
               onMenuItemClicked={onMenuItemClicked}
               name={`${userInfo?.firstName} ${userInfo?.lastName}`}
               email={userInfo?.email}
               loginType={userInfo?.loginType}/>
      <div className="bgresetPassTopRight dvresetPassHeaderTop">
        <div>
          <span className="resetPassSpanHeader2">
            {
            t('RESET_PASSWORD')
          }</span>
        </div>
        <div className="padingHorizontal">
          <span className="resetPassSpanHeader">
            {
            t('CHANGE_YOUR_PASSWORD_HERE')
          } </span>
        </div>
      </div>
      <div className="dvresetPassHeader">
        <div className="resetPassCover resetPassPaddingTop">
          <form className="resetPassword-form" onSubmit={changePassword}>
            <Box sx={
              {flexGrow: 1}
            }>
              <Grid container direction="row" justifyContent="center" alignItems="center"
                className={"resetPassGridHeightEdit"}>
                <Grid item
                  xs={2}
                  >
                  <label className="resetPassColorText">
                    {
                    t('CURRENT_PASSWORD')
                  }</label>
                </Grid>
                <Grid item
                  xs={10}
                  >
                  <TextFieldComponent value={currentPassWord}
                    id="txtCurrentPassword"
                    label={
                      t('CURRENT_PASSWORD')
                    }
                    variant="outlined"
                    fullWidth
                    size="normal"
                    style={textFieldStyle}
                    focused
                    required
                    className={"resetPassReadOnlyInput"}
                    onChange={onCurrentPasswordChanged}
                   
                    type={showCurrentPassword ? 'text' : 'password'}
                    InputProps={{
                      //startAdornment: <InputAdornment position="start">kg</InputAdornment>,
                      endAdornment: <>
                            <InputAdornment position="end">
                              <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={onClickShowCurrentPassword}
                                  onMouseDown={onMouseDownPassword}
                                  edge="end"
                                  sx={[{color:'#0050C3'}, incorrectCurrentPassword? {marginRight:'5px'}: null]}
                              >
                                  {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                            {
                            (incorrectCurrentPassword) && <StyledTooltip title={t('CURRENT_PASSWORD_IS_INCORRECT')} placement="right" />
                            }
                            
                      </>
                    }}
                    inputProps={
                      {readOnly: isProcessing,
                        "data-testid":"currentpwd"}
                      
                    }
                    />
                  </Grid>
              </Grid>
              <Grid container direction="row" justifyContent="center" alignItems="center"
                className={"resetPassGridHeightEdit"}>
                <Grid item
                  xs={2}
                 >
                  <label className="resetPassColorText">
                    {
                    t('NEW_PASSWORD')
                  }</label>
                </Grid>
                <Grid item
                  xs={10}
                 >
                  <TextFieldComponent value={newPassWord}
                    id="txNewPassword"
                    label={
                      t('NEW_PASSWORD')
                    }
                    variant="outlined"
                    fullWidth
                    style={textFieldStyle}
                    size="normal"
                    focused
                    required
                    className= "resetPassReadOnlyInput"
                    onChange={onNewPassWordChanged}
 		    type={showNewPassword ? 'text' : 'password'}                    
		    InputProps={{
                      //startAdornment: <InputAdornment position="start">kg</InputAdornment>,
                      endAdornment: <>
                            <InputAdornment position="end">
                              <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={onClickShowNewPassword}
                                  onMouseDown={onMouseDownPassword}
                                  edge="end"
                                  sx={[{color:'#0050C3'}, (clientValidatedRequirements.length > 0 && newPassWord && !requirementsMet)? {marginRight:'5px'}: null]}
                              >
                                  {showNewPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                            {
                            (clientValidatedRequirements.length > 0 && newPassWord && !requirementsMet)
                            && <StyledTooltip title={<PasswordPolicy validateRequirements={clientValidatedRequirements} />} placement="right" />
                            }
                            
                      </>
                      
                    }}
                    inputProps={
                      {readOnly: isProcessing,
                        "data-testid":"newpwd"}
                    }
                  />

                </Grid>
              </Grid>
              <Grid container direction="row" justifyContent="center" alignItems="center" className="resetPassGridHeightEdit">
                <Grid item
                  xs={2}
                  >
                  <label className="resetPassColorText">
                    {
                    t('CONFIRM_NEW_PASSWORD')
                  }</label>
                </Grid>
                <Grid item
                  xs={10}
                 >
                  <TextFieldComponent value={confirmNewPassword}
                    id="confirmNewPass"
                    label={
                      t('CONFIRM_NEW_PASSWORD')
                    }
                    variant="outlined"
                    fullWidth
                    style={textFieldStyle}
                    size="normal"
                    focused
                    required
                    className={"resetPassReadOnlyInput"}
                    onChange={onConfirmNewPasswordChanged}
                    type={showConfirmPassword ? 'text' : 'password'}
                    InputProps={{
                      //startAdornment: <InputAdornment position="start">kg</InputAdornment>,
                      endAdornment: <>
                            <InputAdornment position="end">
                              <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={onClickShowConfirmPassword}
                                  onMouseDown={onMouseDownPassword}
                                  edge="end"
                                  sx={[{color:'#0050C3'}, doPasswordsDiffer? {marginRight:'5px'}: null]}
                              >
                                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                            {
                            doPasswordsDiffer && <StyledTooltip title={t('PASSWORD_AND_CONFIRM_PASSWORD_DO_NOT_MATCH')} placement="right" />
                            }
                            
                      </>
                      
                    }}
                    inputProps={
                      {
                        readOnly: isProcessing,
                        maxHeight:42,
                        "data-testid":"confirmnewpwd"
                      }
                     
                    }
                    />
                </Grid>
              </Grid>

              <Grid container direction="row" justifyContent="center" alignItems="center" className="resetPassGridHeightEdit">
                <Grid item
                  xs={2}
                  />
                <Grid item
                  xs={10}
                  >
                  <Stack direction="row"
                    spacing={2}
                    justifyContent="flex-end"
                    alignItems="center"
                    style={{maxWidth:665,marginLeft:15}}
                    >
                    <Button variant="outlined" className="resetPassButtonNormal"
                      onClick={cancelSave}
                      disabled={isProcessing}>
                      <span className="resetPassSpanNone">
                        {
                        t('CANCEL')
                      }</span>
                    </Button>
                    <Button type='submit' data-testid='saveButton' variant="contained" color="primary"
                      disabled={
                          isProcessing
                          || !(currentPassWord && newPassWord && confirmNewPassword && newPassWord === confirmNewPassword)
                          || (clientValidatedRequirements.length > 0 && !requirementsMet)
                      }>
                      {
                      isProcessing ? <CircularProgress size={'24px'} sx={
                        {p: '4px'}
                      }/> : <span className="resetPassSpanNone">
                        {
                        t('SAVE')
                      }</span>
                    } </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </form>
        </div>
      </div>
    </div>
  );
}

