import React, { useState } from "react";
import "./MfaSignIn.scss";
import { Button, Box, Stack, Typography, CircularProgress } from "@mui/material";
import CssTextField from "../../../components/CssTextField";
import Link from '@mui/material/Link';
import { STATUS } from "../../../config/loginStatus";
import { useTranslation } from "react-i18next";
import CircularLoading from "../../../components/CircularLoading";
import authenticationService from "../../../services/authenticationService";


export default function MfaSignInCodeLoginFlow({
    onStatusChanged,
    authMethod,
    showToastMessage,
    onLoginSuccess,
    selectedDevice,
    onLoginFailed,
    onCancel
}) {

    const [code, setCode] = useState("");
    const [isLoading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isResendProcessing, setIsResendProcessing] = useState(false);
    const { t } = useTranslation();
    
    const onCodeChanged = (event) => {
        setCode(event.target.value)
    }      
    
    const onContinueLogin=(event)=>{
        event.preventDefault();
        setIsProcessing(true);
        authenticationService.authenticateOTP("/sso/v1/login/device/otp",code).then((response)=>{
            setIsProcessing(false);
            if(response.data && !(response.data instanceof Error) && response.data?.status === 200){
                if(response.data?.data?.sid) {                               
                    onLoginSuccess(response.data?.data?.sid,response.data?.data?.timeOut);                
                }
            } else {
                if(response?.data?.response?.data?.error?.[0]?.code === "NOT_FOUND"){
                    onLoginFailed();
                  }
                  else{
                    showToastMessage(t("CODE_IS_INVALID_OR_EXPIRED_REQUEST_A_NEW_CODE"));
                  }
            }
        })
        .catch(() => {
            showToastMessage(t("AN_UNEXPECTED_ERROR_HAS_OCCURRED"));
            setIsProcessing(false);
        });
    }   

    const onResendCodeToEmail = async () => {
          setIsResendProcessing(true);
          const devices = JSON.parse(localStorage.getItem("emaildeviceid"));
          let deviceId = selectedDevice?.id ? selectedDevice?.id : (devices?.length === 1 && devices[0].id);
          await authenticationService.sendOTP("/sso/v1/login/device", deviceId)
            .then((response) => {
                if(response.data && !(response.data instanceof Error)){
                    showToastMessage('Code has been sent', 'success');                
                } else {
                    if(response?.data?.response?.data?.error?.[0]?.code === "NOT_FOUND"){
                        onLoginFailed();
                      }
                      else{
                        showToastMessage(t("AN_UNEXPECTED_ERROR_HAS_OCCURRED"));
                      }
                }                
                setIsResendProcessing(false);
            })
            .catch(() => {
                showToastMessage(t("AN_UNEXPECTED_ERROR_HAS_OCCURRED"));
                setIsResendProcessing(false);
            });
    }
    
    if (isLoading) {
        return <div className="mfaContainer"> <CircularLoading /></div>
    } else
    if (authMethod === STATUS.AUTHENTICATION_THROUGH_APPQR_LOGIN) {
        return <form onSubmit={onContinueLogin}>
                <Box className="mfa-code-input">
               <Stack
                   direction="row"
                   justifyContent="center"
                   alignItems="center"
               ><img alt="" style={{ width: "88%", padding: "2rem 1rem 2rem 1rem" }} src="/SurePoint_Logo_Trademark.svg" />
               </Stack>
                   <Typography className="mfaLabel"> {t('ENTER_THE_MFA_CODE_FROM_YOUR_AUTHENTICATOR_APP_TO_SIGN_IN')}</Typography>
                   <CssTextField required autoComplete='new' name="app-qr" type="text" 
                   inputProps={{
                       "data-testid":"appqrlogin"
                   }}
                       value={code}
                       id="outlined-basic" label={t('MFA_CODE')} variant="outlined" fullWidth size="normal" focused autoFocus
                       onChange={onCodeChanged} />
                   <Button type='submit' data-testid="submitButtonqr" variant="contained" fullWidth className="continueButton" disabled={!code || isProcessing}>
                       {isProcessing
                           ? <CircularProgress sx={{ p: '4px' }} />
                           : t('CONTINUE')
                       }</Button>
                  
                     <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Typography ><Link href="#" underline="always" onClick={() => onStatusChanged(STATUS.MFA_DEVICES_LIST_LOGIN)}>{t('TRY_ANOTHER_METHOD')}</Link></Typography>
                    </Stack>
               </Box>
               </form>
    } else if (authMethod === STATUS.AUTHENTICATION_THROUGH_SMS_LOGIN) {
        return <form onSubmit={onContinueLogin}>
                <Box className="mfa-code-input">
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    ><img alt="" style={{ width: "88%", padding: "2rem 1rem 2rem 1rem" }} src="/SurePoint_Logo_Trademark.svg" />
                    </Stack>
                        <Typography className="mfaLabel"> {t('ENTER_THE_CODE_RECEIVED_TO_COMPLETE_SMS_PAIRING')}</Typography>
                        <CssTextField required autoComplete='new' name="sms" type="text" 
                        inputProps={{
                            "data-testid":"smslogin"
                        }}
                            value={code}
                            id="outlined-basic" label={t('MFA_CODE')} variant="outlined" fullWidth size="normal" focused autoFocus
                            onChange={onCodeChanged} />
                        <Button type='submit' data-testid="submitButtonsms" variant="contained" fullWidth className="continueButton" disabled={!code || isProcessing} >
                            {isProcessing
                                ? <CircularProgress sx={{ p: '4px' }} />
                                : t('CONTINUE')
                            }</Button>
                         <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            >
                        <Typography className="resend-link">
                                {isResendProcessing ? <CircularProgress sx={{p:'4px'}}/> : 
                        <Link href="#" underline="none" onClick={() => onResendCodeToEmail()}>{t('RESEND_CODE')}</Link>}                            
                        </Typography>
                    </Stack>    
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                          >
                        <Typography><Link href="#" underline="always" onClick={() => onStatusChanged(STATUS.MFA_DEVICES_LIST_LOGIN)}>{t('TRY_ANOTHER_METHOD')}</Link></Typography>
                    </Stack>
                </Box>
                </form>
    } else if (authMethod === STATUS.AUTHENTICATION_THROUGH_EMAIL_LOGIN) {
        return   <form onSubmit={onContinueLogin}>
                    <Box className="mfa-code-input">
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    ><img alt="" style={{ width: "88%", padding: "2rem 1rem 2rem 1rem" }} src="/SurePoint_Logo_Trademark.svg" />
                    </Stack> 
                    <Typography className="form-label"> {t('ENTER_THE_MFA_CODE_SENT_TO_EMAIL')}</Typography>
                    <CssTextField required autoComplete = 'new' name="hello" type={'text'} 
                    // inputProps={{
                    //         autoComplete: 'new-password',
                    //         // form: {
                    //         //     autoComplete: 'off',
                    //         // },
                    //     }}                        
                        value={code}
                        id="outlined-basic" label={t('MFA_CODE')} variant="outlined" fullWidth size="normal" focused autoFocus
                        onChange={onCodeChanged} />
                    <Button type="submit" variant="contained" fullWidth className="continueButton" disabled={!code || isProcessing} >
                        {isProcessing ? <CircularProgress sx={{p:'4px'}}/> : t('CONTINUE')}</Button>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Typography className="resend-link">
                        {isResendProcessing ? <CircularProgress sx={{p:'4px'}}/> : 
                        <Link href="#" underline="none" onClick={() => onResendCodeToEmail()}>{t('RESEND_CODE')}</Link>}                            
                        </Typography>
                    </Stack>
                    {JSON.parse(localStorage.getItem('emaildeviceid'))?.length > 1 && <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Typography ><Link href="#" underline="always" onClick={() => onStatusChanged(STATUS.MFA_DEVICES_LIST_LOGIN)}>{t('TRY_ANOTHER_METHOD')}</Link></Typography>
                    </Stack>}
                </Box>
                </form>
    }
}
