
import httpModule from "../utils/httpModule";
import config from '../config';
import axios from "axios";
/**
 * Recover a user’s forgotten password.
 * @param apiPath PingOne for Customers authorization and authentication endpoint
 * @param username user name
 */
const authenticate = async (username, password) => {
    const headers = {
    'Content-Type': 'application/json',
  }
  const http = httpModule(config.ssoApiBaseUrl);
  return http.post('/sso/v1/login', {
          username:username,
          password:password
        },null, headers);

};

const authenticateV2 = async (code) => {
  const headers = {
  'Content-Type': 'application/json',
}
const http = httpModule(config.ssoApiBaseUrl);
return http.post(`/sso/v2/login?code=${code}`,null,null, headers);

};

const readFlow=async(apiPath)=>{
  const http = httpModule(config.AUTH_URI);
  return http.get(apiPath);
}

const signoutUser=async(envId,idToken)=>{
   let signoutUrl=`${config.AUTH_URI}/${envId}/as/signoff?id_token_hint=${idToken}&post_logout_redirect_uri=${config.logoutRedirectUri}`
   window.location.assign(signoutUrl);
}

const resumeFlow=async(apiPath)=>{
  const http = httpModule(config.AUTH_URI);
  return http.get(apiPath);
}

const getConstructedAuthUrl=()=>{
  //use the config file
  return `${config.AUTH_URI}/${config.environmentId}/as/authorize?response_type=token id_token&client_id=${config.clientId}&redirect_uri=${config.redirectUri}&scope=${config.scope}&prompt=${config.prompt}`;
}
const authenticateOTP=(endpoint,code)=>{
  const headers = {
    'Content-Type': 'application/json',
    'sid': localStorage.getItem('sid')
  }
  const http = httpModule(config.ssoApiBaseUrl);
  return http.post(endpoint, {
          otp:code
        },null, headers);
}

const sendOTP=(endpoint, deviceid)=>{
  const headers = {
    'Content-Type': 'application/json',
    'sid': localStorage.getItem('sid')
  }
  const http = httpModule(config.ssoApiBaseUrl);
  return http.post(endpoint, {
    deviceId: deviceid
        },null, headers);
}

const samlResume=(flowId,sid)=>{
  if(flowId){
    const headers = {
      'Content-Type': 'application/json',
      'sid': sid
    }
    const http = httpModule(config.ssoApiBaseUrl);
    return http.post(`/sso/v1/samlResume?flowId=${flowId}`,null,null, headers);
  }
}

export const loginUserNameCheck=(username)=>{
    const headers = {
      'Content-Type': 'application/json',
    }
    const http = httpModule(config.ssoApiBaseUrl);
    return http.post(`sso/v2/login/username`,{username},null, headers);
}

const loginPasswordCheck=(password,flowId)=>{
  const headers = {
    'Content-Type': 'application/json',
  }
  const http = httpModule(config.ssoApiBaseUrl);
  return http.post(`sso/v2/login/password`,{password,flowId},null, headers);
}

const loginWithCode=(code)=>{
  const headers = {
    'Content-Type': 'application/json',
  }
  const http = httpModule(config.ssoApiBaseUrl);
  return http.post(`sso/v2/login/code`,{code},null, headers);
}

export default {
  authenticate,
  loginPasswordCheck,
  loginWithCode,
  signoutUser,
  readFlow,
  resumeFlow,
  getConstructedAuthUrl,
  authenticateOTP,
  sendOTP,
  samlResume,
  authenticateV2
};
