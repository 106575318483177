const jsUtils = {
  debFlag: null,
  debounce: (doSomething, time, ...params) => {
    if (jsUtils.debFlag) {
      window.clearTimeout(jsUtils.debFlag);
      jsUtils.debFlag = null;
    }
    if (!jsUtils.debFlag) {
      jsUtils.debFlag = window.setTimeout(() => {
        jsUtils.debFlag = null;
        doSomething && doSomething(...params);
      }, time);
    }
  },
  objToQS: (obj) => {
    return Object.keys(obj).reduce((a, b) => {
      return obj[b] ? a + b + "=" + obj[b] + "&" : a;
    }, "");
  },

  sortTable: (localSort, dataBackup, data = []) => {
    if (localSort.propName !== "" && localSort.index !== -1) {
      data = data.sort((a, b) => {
        return localSort.isAsc
          ? a[localSort.propName] && a[localSort.propName].toString().toLowerCase() >
          b[localSort.propName] && b[localSort.propName].toString().toLowerCase()
            ? 1
            : -1
          : a[localSort.propName] && a[localSort.propName].toString().toLowerCase() >
          b[localSort.propName] && b[localSort.propName].toString().toLowerCase()
          ? -1
          : 1;
      });
      return JSON.parse(JSON.stringify(data));
    } else {
      return JSON.parse(JSON.stringify(dataBackup));
    }
  },
  updateRowData: (rowId, newRow, data, dataBackup, setHook, setHookBackup) => {
    // takes aray of rows, and updates one row.
    dataBackup = JSON.parse(JSON.stringify(dataBackup));
    dataBackup.forEach((item, index) => {
      if (item.rowId === rowId) {
        dataBackup[index] = newRow;
      }
    });
    data.forEach((item, index) => {
      if (item.rowId === rowId) {
        data[index] = newRow;
      }
    });
    setHookBackup && setHookBackup(JSON.parse(JSON.stringify(dataBackup)));
    setHook && setHook(JSON.parse(JSON.stringify(data)));
  },
  deleteRow: (rowId, data, dataBackup, setHook, setHookBackup) => {
    data = data.filter((d) => d.rowId !== rowId);
    dataBackup && (dataBackup = dataBackup.filter((d) => d.rowId !== rowId));
    setHookBackup && setHookBackup(JSON.parse(JSON.stringify(dataBackup)));
    setHook && setHook(JSON.parse(JSON.stringify(data)));
  },
  addRow: (rowToAdd, data, dataBackup, setHook, setHookBackup) => {
    data.unshift(rowToAdd);
    dataBackup.unshift(rowToAdd);
    setHookBackup && setHookBackup(JSON.parse(JSON.stringify(dataBackup)));
    setHook && setHook(JSON.parse(JSON.stringify(data)));

  },
  editMode: (rowId, editMode, data, dataBackup, setHook, setHookBackup,editStartDate) => {
    data.forEach((item, index) => {
      if (item.rowId === rowId) {
        data[index] = { ...data[index], editMode: editMode ,editStartDate:editStartDate};
      }
    });
    dataBackup.forEach((item,index)=>{
      if(item.rowId === rowId){
        dataBackup[index] = { ...dataBackup[index], editMode: editMode ,editStartDate:editStartDate};
      }
    })
    setHook && setHook(JSON.parse(JSON.stringify(data)));
    setHookBackup && setHookBackup(JSON.parse(JSON.stringify(dataBackup)));
  },

  updateACellOrProp: (rowId, propName, newData, data, setHook) => {
    data = JSON.parse(JSON.stringify(data));
    data.forEach((item, index) => {
      if (item.rowId === rowId) {
        item[propName] = newData;
      }
    });
    setHook && setHook(JSON.parse(JSON.stringify(data)));
  },

  getRandomNumber: () => {
    const crypto = window.crypto || window.msCrypto;
    var array = new Uint32Array(1);
    return crypto && crypto.getRandomValues(array)[0];
  },
  setDataToSessionStorage: (clientItem)=>{
    let now=new Date()
    clientItem["fetchedTimeInSecs"] = Math.round(now.getTime() / 1000)
    sessionStorage.setItem("timerKeyLazy",JSON.stringify(clientItem))
  },
  getDataFromSessionStorage: ()=>{
    let getTimer= sessionStorage.getItem("timerKeyLazy");
    if(getTimer)
    {
      return JSON.parse(getTimer);
    }
    else{
      return null
    }
  },
  updatedTime: (runningTimer,additionalTimeInSeconds)=>{
    let [hh,mm,ss]=runningTimer.split(":")
    let finalSeconds,finalHours,finalMins;
    let startTime= (Number(hh)*3600) + (Number(mm)*60) + Number(ss)+ additionalTimeInSeconds
    finalHours=Math.trunc(startTime/3600);
    finalMins=Math.trunc((startTime % 3600)/60);
    finalSeconds=Math.trunc(((startTime % 3600)%60));
    let finalTime=(('0' + finalHours).length<3?('0' + finalHours):finalHours)+":"+(('0' + finalMins).slice(-2))+":"+(('0' + finalSeconds).slice(-2))
    return finalTime;

  },
  // getLiteralValue: (key) => {
  //   return storage.getObject("literals") && storage.getObject("literals")[key] ? storage.getObject("literals")[key] : key;
  // },

  getWeekDayArrayInNumberFormats:(weekDays)=>{
    let selectedWeekNumberArray=[];
    weekDays.forEach((day)=>{
      if(day==="Sun")
      {
        selectedWeekNumberArray.push(0)
      }
      if(day==="Mon")
      {
        selectedWeekNumberArray.push(1)
      }
      if(day==="Tue")
      {
        selectedWeekNumberArray.push(2)
      }
      if(day==="Wed")
      {
        selectedWeekNumberArray.push(3)
      }
      if(day==="Thu")
      {
        selectedWeekNumberArray.push(4)
      }
      if(day==="Fri")
      {
        selectedWeekNumberArray.push(5)
      }
      if(day==="Sat")
      {
        selectedWeekNumberArray.push(6)
      }
    })
    return selectedWeekNumberArray
  },
  // getCurrentServerDate: (timezone) => {
  //   let finalCurrDate = null;
  //   if(timezone) {
  //   const currentDate = tzmoment().tz(timezone).format();
  //   const currDate = currentDate.substring(0, 10).split("-");
  //   finalCurrDate = currDate[0] + "/" + currDate[1] + "/" + currDate[2];
  //   }
  //   return finalCurrDate;
  // },
  checkIfCustomTableFilterChanged : (_colDef, _defaultValue) => {
    let status = true;
    _colDef.forEach((item, index)=>{
      if(item.filter.val !== null ? item.filter.val !== _defaultValue[index]: false){
        status =  false;
        return status
      }
    });
    return status
  },

  getDatePlusMinus : (date, days) =>{
    let dt = new Date(date);
    let newdate = new Date(dt);
    newdate.setDate(newdate.getDate() + days);
    return newdate;
  },
  getTimeZoneFormatDate : (date) =>{
    if(typeof date === "string" && date.includes("-")){
      let formattedDate = date.substring(0, 10).split("-");
      formattedDate = formattedDate[1] + "/" + formattedDate[2] + "/" + formattedDate[0];
      return formattedDate;
    }
    else{
      return date;
    }
  },

  getMinDate : (currentDate,startDate,mode) =>{
    let mindate=null;
    if(mode==="edit"){
      mindate=new Date(jsUtils.getTimeZoneFormatDate(startDate)) >
              new Date(jsUtils.getTimeZoneFormatDate(currentDate)) ? new Date(jsUtils.getTimeZoneFormatDate(startDate)) : new Date(jsUtils.getTimeZoneFormatDate(currentDate))
    }
    else{
     mindate= new Date(jsUtils.getTimeZoneFormatDate(startDate)) >
              new Date(jsUtils.getTimeZoneFormatDate(currentDate)) ? new Date(jsUtils.getDatePlusMinus(jsUtils.getTimeZoneFormatDate(startDate),1)) : new Date(jsUtils.getDatePlusMinus(jsUtils.getTimeZoneFormatDate(currentDate),1))

    }
    return mindate;
  },

  getMaxDate : (currentDate,checkedDate) =>{
    let maxDate=null;
    if(checkedDate && currentDate){
      maxDate=new Date(jsUtils.getTimeZoneFormatDate(checkedDate)) >
      new Date(jsUtils.getTimeZoneFormatDate(currentDate)) ? new Date(jsUtils.getTimeZoneFormatDate(checkedDate)) : new Date(jsUtils.getTimeZoneFormatDate(currentDate)) ;
    }
    else{
      if(currentDate){
        maxDate=new Date(jsUtils.getTimeZoneFormatDate(currentDate))
      }
      else{
        maxDate=new Date(jsUtils.getTimeZoneFormatDate(checkedDate))
      }
    }


    return maxDate;

  },

   getValidatedColumns : (_DayViewColDef,_selectedColumns)=>{
    let newColumnDef=_DayViewColDef.filter((item)=>(_selectedColumns.findIndex((finalCol)=>(finalCol.columnName === item.column.columnName) && finalCol.col_enable) !== -1)) || [] ;
    let sortedColumnDef=[];
    _selectedColumns.forEach((item)=>{
      let searchedColumn=newColumnDef.find((newColumn)=>newColumn.column.columnName===item.columnName);
      if(searchedColumn)
      {
        sortedColumnDef.push(searchedColumn);
      }
    })
    sortedColumnDef=[...sortedColumnDef];
    return sortedColumnDef;
  },

  generateRandomValue : () => {
    let crypto = window.crypto || window.msCrypto;
    let D = new Uint32Array(2);
    crypto.getRandomValues(D);
    return D[0].toString(36);
  },
  stringToColor: (string) =>{
    let hash = 0;
    let i;
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
    return color;
  },
  stringAvatar: (name) =>{
    const stringAvatar = jsUtils.stringToColor(name);
    return {
      sx: {
        bgcolor: stringAvatar,
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  },
  getUrlParameters:(url)=> {

    // get query string from url (optional) or window
    var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

    // we'll store the parameters here
    var obj = {};

    // if query string exists
    if (queryString) {

      // stuff after # is not part of query string, so get rid of it
      queryString = queryString.split('#')[0];

      // split our query string into its component parts
      var arr = queryString.split('&');

      for (var i = 0; i < arr.length; i++) {
        // separate the keys and the values
        var a = arr[i].split('=');

        // set parameter name and value (use 'true' if empty)
        var paramName = a[0];
        var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

        // (optional) keep case consistent
        paramName = paramName.toLowerCase();
        if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

        // if the paramName ends with square brackets, e.g. colors[] or colors[2]
        if (paramName.match(/\[(\d+)?\]$/)) {

          // create key if it doesn't exist
          var key = paramName.replace(/\[(\d+)?\]/, '');
          if (!obj[key]) obj[key] = [];

          // if it's an indexed array e.g. colors[2]
          if (paramName.match(/\[\d+\]$/)) {
            // get the index value and add the entry at the appropriate position
            var index = /\[(\d+)\]/.exec(paramName)[1];
            obj[key][index] = paramValue;
          } else {
            // otherwise add the value to the end of the array
            obj[key].push(paramValue);
          }
        } else {
          // we're dealing with a string
          if (!obj[paramName]) {
            // if it doesn't exist, create property
            obj[paramName] = paramValue;
          } else if (obj[paramName] && typeof obj[paramName] === 'string'){
            // if property does exist and it's a string, convert it to an array
            obj[paramName] = [obj[paramName]];
            obj[paramName].push(paramValue);
          } else {
            // otherwise add the property
            obj[paramName].push(paramValue);
          }
        }
      }
    }

    return obj;
  },
  parseHash : (hashUrl) => {
    return hashUrl?.replace('#', '').split('&').reduce(
        (prev, item) => {
          return Object.assign(
              {[item.split('=')[0]]: decodeURIComponent(item.split('=')[1])},
              prev);
        }, {});

  },
  phoneNumberFormat: (tel) =>{
    let toString = String(tel),
      phoneNumberTemp = toString.replace(/[^0-9]/g, ""),
      countArrayStr = phoneNumberTemp.split(""),
      numberVar = countArrayStr.length,
      closeStr = countArrayStr.join("");
    let phone="";
    if (numberVar === 1) {
      phone = closeStr.replace(/(\d{1})/, "$1"); // Change number symbols here for numbers 10 digits in length. Just change the periods to what ever is needed.
    } else if (numberVar === 2) {
      phone = closeStr.replace(/(\d{2})/, "$1"); // Change number symbols here for numbers 10 digits in length. Just change the periods to what ever is needed.
    } else if (numberVar === 3) {
      phone = closeStr.replace(/(\d{3})/, "$1"); // Change number symbols here for numbers 10 digits in length. Just change the periods to what ever is needed.
    } else if (numberVar === 4) {
      phone = closeStr.replace(/(\d{3})(\d{1})/, "$1-$2"); // Change number symbols here for numbers 10 digits in length. Just change the periods to what ever is needed.
    } else if (numberVar === 5) {
      phone = closeStr.replace(/(\d{3})(\d{2})/, "$1-$2"); // Change number symbols here for numbers 10 digits in length. Just change the periods to what ever is needed.
    } else if (numberVar === 6) {
      phone = closeStr.replace(/(\d{3})(\d{3})/, "$1-$2"); // Change number symbols here for numbers 10 digits in length. Just change the periods to what ever is needed.
    } else if (numberVar === 7) {
      phone = closeStr.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3"); // Change number symbols here for numbers 10 digits in length. Just change the periods to what ever is needed.
    } else if (numberVar === 8) {
      phone = closeStr.replace(/(\d{3})(\d{3})(\d{2})/, "$1-$2-$3"); // Change number symbols here for numbers 10 digits in length. Just change the periods to what ever is needed.
    } else if (numberVar === 9) {
      phone = closeStr.replace(/(\d{3})(\d{3})(\d{3})/, "$1-$2-$3"); // Change number symbols here for numbers 10 digits in length. Just change the periods to what ever is needed.
    } else if (numberVar === 10) {
      phone = closeStr.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"); // Change number symbols here for numbers 10 digits in length. Just change the periods to what ever is needed.
    }   
    else {
      phone = "";
    }
    return phone;
  
  },
  getProtectedEmail: (userEmail) => {
    let avg, splitted, part1, part2;
    splitted = userEmail?.split("@");
    part1 = splitted[0];
    avg = part1.length / 2;
    part1 = part1?.substring(0, (part1.length - avg-1));
    part2 = splitted[1];
    return part1 + "****@" + part2;
  },
  getProtectedPhoneNumber: (phoneNumber) => {
    return phoneNumber?.substring(0, (phoneNumber.length - 4)) + "****";
  },
  parseError : (arrErr) => {
    let errorDetail = "";
    arrErr.forEach(element => {
      errorDetail = errorDetail + element.target + " " + element.message + "\n";
    });
     return errorDetail;
  },
  getGMTOffset: ()=>{
    const rightNow = new Date();
    const gmtDiff = rightNow.getTimezoneOffset() / 60;
    return gmtDiff;
  }
};
export default jsUtils;



