import React, { useEffect, useState } from "react";
import "./Login.scss";
import SignIn from "./SignIn/SignIn";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import ResetPassword from "./ResetPassword/ResetPassword";
import { STATUS } from "../../config/loginStatus";
import ToastMessage, { MESSAGE_TYPE } from "../../components/ToastMessage";
import MFAContainerLoginFlow from "./MfaSignIn/MFAContainerLoginFlow";
import { useTranslation } from "react-i18next";
import jsUtils from "../../utils/jsUtils";

export default function Login({onLoginSuccess, flowId, environmentId, parentFlow, updateFlow, _status = STATUS.SIGN_IN,setUserLoggedIn}) {

    const [status, setStatus] = useState(_status);
    const [message, setMessage] = useState('');
    const [userInfo, setUserInfo] = useState({});
    const [messageType, setMessageType] = useState('error');
    const [arrRegisteredMFAMethod, setArrRegisteredMFAMethod] = React.useState([
      {id:1, title:'Email', content:'j**D@law.com', type:'email'},
      {id:2,title:'Text Message', content:'******789', type:'phone'},
      {id:3, title:'Authenticator App', content:'Microsoft Authenticator', type:'auth'}
    ]);
    const { t } = useTranslation(); 
    const [resetEmail,setResetEmail] = useState("");
    const changeStatus = (_status) => {
      setStatus(_status);
    }
    
    useEffect(()=>{
      document.title="Sign In - SurePoint";
      const queryString = window.location.search;
      const urlParams=new URLSearchParams(queryString);
      const flowId=urlParams.get('flowId');
      if(flowId){
        localStorage.setItem('samlFlowId',flowId);
      }
    },[])

    const showToastMessage = (message, type = MESSAGE_TYPE.ERROR) => {
      setMessage(message);
      setMessageType(type);
    }

    const handleClose = () => {
      setMessage('');
    }  
    
    const onEmailOrPhoneNumberChange = (value) => {
      setUserInfo({...userInfo, ...value});
    }

  const setMfaDeviceList = (devices) => {
    setArrRegisteredMFAMethod(devices);
  }

  const onLoginFailed =()=>{
    showToastMessage(t("LOGIN_SESSION_EXPIRED"));
    localStorage.clear();
    setStatus(STATUS.SIGN_IN);
    }

    const renderComponent = (_status) => {      
      switch (_status) {        
        case STATUS.FORGOT_PASSWORD:
          return <ForgotPassword flow={parentFlow} setResetEmail={setResetEmail} onStatusChanged={changeStatus}
           updateFlow={updateFlow} showToastMessage={showToastMessage} onCancel={(status) => setStatus(status)}/>;
        case STATUS.RESET_PASSWORDD:
          return <ResetPassword flow={parentFlow} resetEmail={resetEmail} onStatusChanged={changeStatus} 
          updateFlow={updateFlow} showToastMessage={showToastMessage} onEmailOrPhoneNumberChange={onEmailOrPhoneNumberChange}
          setMfaDeviceList={setMfaDeviceList} onLoginSuccess={onLoginSuccess} onLoginFailed={onLoginFailed} onCancel={(status) => setStatus(status)}/>;
        case STATUS.SIGN_IN:     
        default:  
          return <SignIn onStatusChanged={changeStatus} flow={parentFlow} onLoginSuccess={onLoginSuccess} flowId={flowId}
           environmentId={environmentId} updateFlow={updateFlow} 
           showToastMessage={showToastMessage} onEmailOrPhoneNumberChange={onEmailOrPhoneNumberChange}
           setMfaDeviceList={setMfaDeviceList}
           onLoginFailed={onLoginFailed} />
      }
    }

  if(status === STATUS.MFA_DEVICES_LIST_LOGIN || status === STATUS.MFA_CODE_ON_EMAIL_LOGIN) {
    return <MFAContainerLoginFlow 
    userInfo={userInfo} 
    onLoginSuccess={onLoginSuccess} 
    mfaDeviceList={arrRegisteredMFAMethod}    
    onLoginFailed={onLoginFailed}       
    onCancel={(status) => setStatus(status)}  
    _status={status}
    /> 
  } else {
  return <div className="cover">
      <ToastMessage message={message} type={messageType} onClose={handleClose} />
       <img alt="" style={{ width: "88%", padding: "2rem 1rem 2rem 1rem" }} src="/SurePoint_Logo_Trademark.svg" />
      {renderComponent(status)}
    </div>
  }  
}
