
import httpModule from "../utils/httpModule";
/**
 * Recover a user’s forgotten password.
 * @param apiPath PingOne for Customers authorization and authentication endpoint
 * @param username user name
 */
const forgotPassword = async (baseUrl, apiPath, username) => {
  const http = httpModule(baseUrl);
  const headers = {
    'Content-Type': 'application/json',
  }
  return http.post(apiPath, { username }, {}, headers);
};

/**
 * Send the OTP to the user. The OTP is a randomly generated eight-character alphanumeric string sent to the user’s email address, and the code is valid for five minutes.
 * @param apiPath PingOne for Customers authorization and authentication endpoint
 */
const sendRecoveryCode = async (baseUrl, apiPath) => {
  const http = httpModule(baseUrl);
  const headers = {
    'Content-Type': 'application/vnd.pingidentity.password.sendRecoveryCode+json',
  }
  return http.post(apiPath, {}, {}, headers);
};

/**
 * Recover the account and set a new password.
 * @param apiPath PingOne for Customers authorization and authentication endpoint
 * @param recoveryCode
 * @param newPassword
 */
const resetPassword = async (baseUrl, apiPath, recoveryCode, newPassword,sid) => {
  const http = httpModule(baseUrl);
  const headers = {
    'Content-Type': 'application/json',
    'sid':sid
  }
  return http.post(apiPath, { recoveryCode, newPassword }, {}, headers);
};

const resume = async (baseUrl, apiPath, flowId) => {
  const http = httpModule(baseUrl);
  return http.get(apiPath, {flowId}, {});
};

const passwordService = {
  forgotPassword,
  sendRecoveryCode,
  resetPassword,
  resume,
}

export default passwordService;
