export const STATUS = {
  SIGN_IN: 0,
  MFA_SIGN_IN: 1,
  FORGOT_PASSWORD: 2,
  RESET_PASSWORDD: 3,
  MANAGE_MFA_METHODS: 4,
  SELECT_AUTHENTICATION_METHOD: 5,
  MFA_CODE_ON_PHONE: 6, 
  MFA_CODE_ON_EMAIL: 7,
  MFA_DEVICES_LIST_LOGIN: 8,
  MFA_CODE_ON_PHONE_LOGIN: 'MFA_CODE_ON_PHONE_LOGIN', 
  MFA_CODE_ON_EMAIL_LOGIN: 'MFA_CODE_ON_EMAIL_LOGIN',
  AUTHENTICATION_THROUGH_EMAIL: 'Email',
  AUTHENTICATION_THROUGH_SMS: 'Text Message',
  AUTHENTICATION_THROUGH_APPQR: 'Authentication App',
  AUTHENTICATION_THROUGH_EMAIL_LOGIN: 'Email Login Flow',
  AUTHENTICATION_THROUGH_SMS_LOGIN: 'Text Message Login Flow',
  AUTHENTICATION_THROUGH_APPQR_LOGIN: 'Authentication Login Flow'
};

export const RESPONSE_STATUS = {
  SUCCESS: 'SUCCESS',
  UNAUTHORIZED: 'UNAUTHORIZED',

  COMPLETED: 'COMPLETED',
  USERNAME_PASSWORD_REQUIRED: 'USERNAME_PASSWORD_REQUIRED',
  PASSWORD_REQUIRED: 'PASSWORD_REQUIRED',
  INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',
  NO_PASSWORD: 'NO_PASSWORD',
  PASSWORD_EXPIRED: 'PASSWORD_EXPIRED',
  PASSWORD_LOCKED_OUT: 'PASSWORD_LOCKED_OUT',
  MUST_CHANGE_PASSWORD: 'MUST_CHANGE_PASSWORD',
  PASSWORD_REQUIREMENTS_NOT_MET: 'PASSWORD_REQUIREMENTS_NOT_MET',
  VERIFICATION_CODE_REQUIRED: 'VERIFICATION_CODE_REQUIRED',
  RECOVERY_CODE_REQUIRED: 'RECOVERY_CODE_REQUIRED',
  INVALID_VALUE: 'INVALID_VALUE',
  UNIQUENESS_VIOLATION: 'UNIQUENESS_VIOLATION',
  UNKNOWN: 'UNKNOWN',
  FAILED: 'FAILED',
  DEVICE_SELECTION_REQUIRED: 'DEVICE_SELECTION_REQUIRED',
  OTP_REQUIRED: 'OTP_REQUIRED'
};
