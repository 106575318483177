import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';

const AlertDialog = ({open, title, message, cancelLabel, confirmLabel, onCancel, onConfirm, isProcessing = false}) => {  

  return (
    <div className=''>
      <Dialog
        open={open}
        onClose={onCancel}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle className="dialog-title">
          <Typography variant='subtitle1'>{title}</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="dialog-description">
          <Typography >{message}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}><Typography className="button-label">{cancelLabel}</Typography></Button>
          <Button onClick={onConfirm} autoFocus>
          {isProcessing ? <CircularProgress sx={{p:'4px', color:'#2DA0EB'}} /> :
            <Typography className="button-label">{confirmLabel}</Typography>}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AlertDialog;