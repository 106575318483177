export const MFA_DEVICE_TYPE = {
  EMAIL: 'EMAIL',
  SMS: 'SMS',
  VOICE: 'VOICE',
  TOTP: 'TOTP',
  MOBILE: 'MOBILE',
  PLATFORM: 'PLATFORM',
  SECURITY_KEY: 'SECURITY_KEY',
};

export const MFA_STATUS = {
  ACTIVE: 'ACTIVE',
  ACTIVATION_REQUIRED: 'ACTIVATION_REQUIRED',
  UNKNOWN: 'UNKNOWN',
};
