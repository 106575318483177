
import React, { useState} from "react";
import "./SignIn.scss";
import { Button, Checkbox,CircularProgress, Link, Stack } from "@mui/material";
import { RESPONSE_STATUS, STATUS } from "../../../config/loginStatus";
import validator from "validator";
import CssTextField from "../../../components/CssTextField";
import authenticationService from "../../../services/authenticationService";
import { useTranslation } from 'react-i18next';
import StyledTooltip from "../../../components/common/StyledTooltip";

import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import PassWordEntryScreen from "./PasswordEntryScreen";
import EmailEntryScreen from "./EmailEntryScreen";

export default function SignIn({onStatusChanged, setMfaDeviceList, showToastMessage, onEmailOrPhoneNumberChange,onLoginSuccess}){

    const [email, setEmail] = useState('');
    const [showPasswordScreen,setShowPasswordScreen] = useState(false);
    const [flowId,setFlowId] = useState(null);
    //end showpassword

    const { t } = useTranslation();

    const onEmailChanged = (event) => {
        setEmail(event.target.value);
    }
    

    return <div className="signInContainer">
        {showPasswordScreen?
        <PassWordEntryScreen
            onStatusChanged={onStatusChanged} 
            setMfaDeviceList={setMfaDeviceList}
            showToastMessage={showToastMessage}
            onEmailOrPhoneNumberChange={onEmailOrPhoneNumberChange}
            setShowPasswordScreen={setShowPasswordScreen}
            onLoginSuccess={onLoginSuccess}
            setFlowId={setFlowId}
            flowId={flowId}
        />:<EmailEntryScreen
             setShowPasswordScreen={setShowPasswordScreen}
             showToastMessage={showToastMessage}
             onEmailChanged={onEmailChanged}
             email={email}
             setFlowId={setFlowId}
        />}
    </div>
}