const MfaMethod = () => {
  return {
    id: null,
    type: null,
    status: null,
    // belong to MFA type, value can be phone number, email, TOTP,
    value: '',
    // display name, using for showing in UI
    nickname: '',
  };
};

export default MfaMethod;