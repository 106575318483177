import React, { useState } from 'react';
import { Box, Modal, Stack, Typography } from "@mui/material";
import './../Login.scss';
import { useTranslation } from 'react-i18next';
import TabletMacOutlinedIcon from '@mui/icons-material/TabletMacOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import { STATUS } from '../../../config/loginStatus';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CrossIcon from '../../../components/common/CrossIcon';

export default function SelectAuthenticationMethod({ onStatusChanged, userInfo, registeredMfaMethods, onPopupMFAMethodClose}) {

  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(true);
  const typoStyle = {
    fontFamily: "Roboto",
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  textAlighn: 'center'
  };
  const onCloseModel = () =>{
    setOpenModal(false);
    onStatusChanged(STATUS.MANAGE_MFA_METHODS);
  }

  const itemTemp =  registeredMfaMethods.find((item)=>{
    return item.type.toUpperCase() === 'SMS';
  });
  return <>
    <Modal
        open={openModal}
        onClose={onCloseModel}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
    <Box className="mfacode-input-box">
    <Stack
      direction="row"
      justifyContent="right"
      alignItems="right"
      marginBottom="10px"
        ><CrossIcon fontSize='small' onClose={onCloseModel}/></Stack>
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      marginBottom="5px"
    >
    <Typography sx={{...typoStyle}}> {t('SELECT_MFA_HEADER_LABEL')}</Typography>
    </Stack>
      <List>
          <ListItem disablePadding data-testid="QRmethod" onClick={() => onStatusChanged(STATUS.AUTHENTICATION_THROUGH_APPQR)}>
            <ListItemButton>
              <ListItemIcon>
              <TabletMacOutlinedIcon color='primary' fontSize='large'/>
              </ListItemIcon>
              <ListItemText primary={t('AUTHENTICATION_THROUGH_APPQR')} secondary={t('USE_AUTHENTICATION_THROUGH_APPQR_INFO')}/>
            </ListItemButton>
          </ListItem>
          {
          !itemTemp && <ListItem data-testid="SMSmethod" disablePadding onClick={() => onStatusChanged(STATUS.AUTHENTICATION_THROUGH_SMS)}>
            <ListItemButton>
              <ListItemIcon>
              <ChatOutlinedIcon color='primary' fontSize='large'/>
              </ListItemIcon>
              <ListItemText primary={t('TEXT_MESSAGE')} secondary={t('RECEIVE_TEXT_MESSAGE_INFO')}/>
            </ListItemButton>
          </ListItem>
          }

        </List>
    </Box>
    </Modal>
  </>
}
