
import config from "../config";
import httpModule from "../utils/httpModule";

const getSid=()=>{
  return localStorage.getItem("sid");
}
/**
 * Get claims about the authenticated end user from UserInfo Endpoint (OAuth 2.0 protected resource)
 * A userinfo authorization request is used with applications associated with the openid resource.
 * @param apiPath API path
 */
const getUserInfo = (apiPath) => {
  const http = httpModule(config.AUTH_URI, localStorage.getItem("accessToken"));
  return http.get(apiPath, {}, {});
};
/**
 * Self-change password.
 * @param apiPath change password API path
 * @param currentPassword current user password
 * @param newPassword new password
 */
const changePassword = async (baseUrl, apiPath, currentPassword, newPassword) => {
  const http = httpModule(baseUrl);
  const headers={
    'sid':getSid()
  }
  return http.put(apiPath, { currentPassword, newPassword }, {}, headers);
};

/**
 * Get user profile
 * @param apiPath API path
 */
const getUserProfile = (apiPath,sid) => {
  const headers={
    'sid':getSid()
  }
  const http = httpModule(config.ssoApiBaseUrl);
  return http.get(apiPath, {}, headers);
};

/**
 * update user profile
 * @param apiPath API path
 * @param userProfile User object
 */
const updateUserProfile = (apiPath, payload) => {
  const headers={
    'sid':getSid()
  }
  const http = httpModule(config.ssoApiBaseUrl);
  return http.put(apiPath, payload,{},headers);
};


const getUserManagementRouteUrl=(sid,timeOut,federatedType=null)=>{
  return `${config.baseUrlMicrofrontend}/userManagement#sid=${sid}&timeOut=${timeOut}&federatedType=${federatedType}`;
}

/**
 * signOut
 * @param apiPath API path
 */
const signOut = (apiPath) => {
  const headers={
    'sid':getSid()
  }
  const http = httpModule(config.ssoApiBaseUrl);
  return http.post(apiPath, {}, {}, headers);
}
const getHtmlFormatSAMLIDP  = (apiPath)=>{
  const headers={
    'sid':getSid()
  }
  const http = httpModule(config.ssoApiBaseUrl);
  return http.post(apiPath, {}, {}, headers);
}

const getQuickSightHtmlFormatSAMLIDP  = (apiPath)=>{
  const headers={
    'sid':getSid()
  }
  const http = httpModule(config.ssoApiBaseUrl);
  return http.post(apiPath, {}, {}, headers);
}

const userService = {
  changePassword,
  getUserInfo,
  getUserProfile,
  updateUserProfile,
  getUserManagementRouteUrl,
  signOut,
  getHtmlFormatSAMLIDP,
  getQuickSightHtmlFormatSAMLIDP
}

export default userService;
