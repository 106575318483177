import React, { useState} from "react";
import "./SignIn.scss";
import { Button, Checkbox,CircularProgress, Link, Stack } from "@mui/material";
import { RESPONSE_STATUS, STATUS } from "../../../config/loginStatus";
import validator from "validator";
import CssTextField from "../../../components/CssTextField";
import authenticationService, { loginUserNameCheck } from "../../../services/authenticationService";
import { useTranslation } from 'react-i18next';
import StyledTooltip from "../../../components/common/StyledTooltip";

import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

export default function EmailEntryScreen({setShowPasswordScreen, showToastMessage, onEmailChanged,email,setFlowId}){
    const { t } = useTranslation();
    const [isProcessing,setIsProcessing] = useState(false);
    const onEmailSubmit = async(event) => {
        event.preventDefault();
        if (validator.isEmail(email)) {
        setIsProcessing(true);
          await  loginUserNameCheck(email)?.then((response) => {
                if(response.data && !(response.data instanceof Error)){
                    if( response.data?.data?.loginType === 'EXTERNAL_AUTHENTICATION_REQUIRED'){
                        // const flowId=urlParams.get('flowId');
                        // if(flowId){
                        //     localStorage.setItem('samlFlowId',flowId);
                        // }
                        const redirectUrl=response.data?.data?.redirectUrl;
                        if(redirectUrl){
                            window.location.assign(redirectUrl);
                        }
                    }
                    else{
                        if(response.data?.data?.loginType==="PASSWORD_REQUIRED"){
                            setFlowId(response.data?.data?.flowId);
                            setShowPasswordScreen(true);
                        }
                        else{
                            showToastMessage(t("NOT_A_VALID_LOGINTYPE"));
                        }
                    }
                }
                else{
                    if(response?.data?.response?.data?.status === 400){
                        showToastMessage(t("INCORRECT_USERNAME_OR_PASSWORD"))
                    }
                    else{
                        showToastMessage(t("AN_UNEXPECTED_ERROR_HAS_OCCURRED"));
                    }
                }
                setIsProcessing(false);
            })
            .catch((ex) => {
                showToastMessage(t("AN_UNEXPECTED_ERROR_HAS_OCCURRED"));
                setIsProcessing(false);
            });

        }
    }

    return <form data-testid="form" autoComplete="on" onSubmit={onEmailSubmit}>
        <CssTextField
            value={email}
            type="email"
            required
            id="outlined-basic" label={t('EMAIL')} variant="outlined" fullWidth size="normal" focused autoFocus={true}
            onChange={onEmailChanged}
            InputProps={{
                endAdornment: ( email && !validator.isEmail(email)) && <StyledTooltip title={t('INVALID_EMAIL_FORMAT')} placement="right-end" />
            }}
            inputProps={{ "data-testid": "email" }}
            />
        <Button data-testid="button" type="submit" value="Submit" variant="contained" fullWidth className="signInButton"  disabled={!validator.isEmail(email) || isProcessing}  >
            {isProcessing? <CircularProgress sx={{p:'4px'}}/> : t('CONTINUE')}
        </Button>
        </form>
    
}
