import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const TextFieldComponent = styled(TextField)({
  '& label.Mui-focused': {
    color: '#005FCC',
  },
  '& span.MuiInputLabel-asterisk': {
    color: '#FF9800',
  },
  '& .MuiOutlinedInput-root': {
    height:'42px',
    '& fieldset': {
      borderRadius: '8px',
      borderColor: '#005FCC',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#005FCC',
      borderWidth: '1px',
    },
  },
  input: {
    maxHeight:'9px',
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset"
    }
  }
});

export default TextFieldComponent;
