import React, { useEffect, useState } from "react";
import "./UserProfileSettings.scss";
import _ from "lodash";
import {
  Button,
  Grid,
  Box,
  Stack,
  CircularProgress,
  Typography,
  useMediaQuery,
} from "@mui/material";
import TextFieldComponent from "../../../components/TextFieldComponent";
import { useTranslation } from 'react-i18next';
import TopMenu from "../../../components/Menu/top-menu";
import userService from '../../../services/userService';
import { MESSAGE_TYPE } from "../../../components/ToastMessage";
import jsUtils from "../../../utils/jsUtils";
import validator from "validator";
import { STATUS } from "../../../config/loginStatus";
import MFAContainer from "../../Login/ManageMFAMethods/MFAContainer";
import StyledTooltip from "../../../components/common/StyledTooltip";

export default function ProfileSetting(props) {
  const {
    isAdmin = false,
    onMenuItemClicked,
    userInfo,
    updateUserInfo,
  } = props;
  const propTemp = {
    backgroundColor: '#fff',
    onMenuItemClicked,
    name: `${userInfo?.firstName} ${userInfo?.lastName ?? ''}`,
    email: userInfo?.email,
  };
  const { t } = useTranslation();
  const [isProcessing, setIsProcessing] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [suffixName, setSuffixName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [manageMFA, setManageMFA] = useState(false);
  const [isMFAEnabled, setIsMFAEnabled] = useState((userInfo?.mfaEnabled === "false"|| userInfo?.mfaEnabled===false)?false:true);
  useEffect(() => {
    if(userInfo) {
        setFirstName(userInfo.firstName || '');
        setLastName(userInfo.lastName || '');
        setMiddleName(userInfo.middleInitial || '');
        setSuffixName(userInfo.suffix || '');
        setEmail(userInfo.email || '');
        setPhoneNumber(jsUtils.phoneNumberFormat(userInfo.phone || ''));
        setIsMFAEnabled((userInfo.mfaEnabled === "false"|| userInfo.mfaEnabled===false)?false:true);
    }
  }, [userInfo])

  useEffect(()=>{
    document.title=t("PROFILE_SETTINGS_TITLE");
    return ()=>{
      document.title=t("LandingPageTitle");
    }
  },[])

  const isRegexConditionSatisfied=(value)=>{
    const expression="^[A-Za-z0-9.'-]*$";
    return value.match(expression);
  }

  const onFirstNameChanged = (event) => {
    isRegexConditionSatisfied(event.target.value) && setFirstName(event.target.value);
  }
  const onLastNameChanged = (event) => {
    isRegexConditionSatisfied(event.target.value) && setLastName(event.target.value);
  }
  const onMiddleNameChanged = (event) => {
    isRegexConditionSatisfied(event.target.value) && setMiddleName(event.target.value);
  }
  const onSuffixNameChanged = (event) => {
    isRegexConditionSatisfied(event.target.value) && setSuffixName(event.target.value);
  }
  const onEmailChanged = (event) => {
    setEmail(event.target.value);
  }
  const onPhoneNumberChanged = (event) => {
    const phone = jsUtils.phoneNumberFormat(event.target.value);
    setPhoneNumber(phone);
  }

  const showToastMessage = (message, type = MESSAGE_TYPE.ERROR) => {
    props.showToastMessage && props.showToastMessage(message, type);
  }

  const cancelSave = () => {
    props.onCancel && props.onCancel();
  }
  const saveUserProfile = (event) => {
    event.preventDefault();
    setIsProcessing(true);
    const body = createRequestBody();
    const endpoint = `/sso/v1/user`;
    userService.updateUserProfile(endpoint, body).then((response) => {
      if (response?.data && !(response.data instanceof Error)) {
        if(response?.data?.status === 200){
          showToastMessage(t('SAVE_MESSAGE_SUCCESSFULL'), MESSAGE_TYPE.SUCCESS);
          updateUserInfo({
          ...userInfo,
          firstName:firstName,
          middleInitial: middleName,
          lastName: lastName,
          suffix: suffixName,
          phone: phoneNumber,
        });
        props.onCancel && props.onCancel();
        }
      } else {
        showToastMessage((response?.data?.error?.[0]?.target || '')+" "+(response?.data?.error?.[0]?.message || ''))
      }
    }).catch((ex) => {
      showToastMessage(t('AN_UNEXPECTED_ERROR_HAS_OCCURRED'));
    }).finally(() => setIsProcessing(false));
  }

  const manageMfaMethods = () => {
    setManageMFA(true);
  }

  const createRequestBody = () => {
    const body = {
      firstName,
      middleInitial: middleName,
      lastName,
      suffix: suffixName,
      phone: phoneNumber,
      email,
    }
    let keys = Object.keys(body);
    for (const att of keys) {
      if (!body[att]) {
        delete body[att];
      }
    }
    return body;
  }

  const isPhoneNumber = (value) => {
    // Simple check if value only contains 10 digits
    const phone = value.replaceAll('-', '');
    return validator.isNumeric(phone) && phone.length === 10;
  }

  const forMobileDevice = useMediaQuery('(max-width: 500px)');
  return (
    <div className="dvprofileSettingContainerMain">
      <TopMenu backgroundColor= '#fff'
               onMenuItemClicked={onMenuItemClicked}
               name={`${userInfo?.firstName} ${userInfo?.lastName}`}
               email={userInfo?.email}
               loginType={userInfo?.loginType} />
      <div className="bgprofileSettingTopRight dvprofileSettingHeaderTop">
        <div>
          <span className="profileSettingSpanHeader2">
            {
              t('PROFILE_SETTINGS')
            }</span>
        </div>
        <div className="padingHorizontal">
          <span className="profileSettingSpanHeader">
            {
              t('CUSTOMIZE_YOUR_PROFILE_SETTINGS_HERE')
            } </span>
        </div>
      </div>
      <div className="dvprofileSettingHeader">
        <div className="profileSettingCover profileSettingPaddingTop">
          <form className="profileSetting-form" onSubmit={saveUserProfile}>
            <Box sx={
              { flexGrow: 1 }
            }>
              <Grid container direction="row" justifyContent="center" alignItems="center" className="profileSettingElementPadding">
                <Grid item
                  xs={2}
                  
                  >
                  <Typography  className="profileSettingColorText">{t('NAME')}</Typography>
                </Grid>
                <Grid item
                  xs={10}
                  
                  >
                  <Stack direction={
                    {
                      xs: "column",
                      sm: "row"
                    }
                  }
                    spacing={4}
                    sx={{maxWidth: 665}}
                    >
                    <TextFieldComponent value={firstName}
                      id="firstName"
                      type="text"
                      label={
                        t('FIRST')
                      }
                      variant="outlined"
                      fullWidth
                      size="normal"
                      focused
                      required
                      className="profileSettingTextInput"
                      onChange={onFirstNameChanged}
                      inputProps={
                        { readOnly: isProcessing ,maxLength: 20 ,"data-testid":"firstName"}
                      }/>
                    <TextFieldComponent id="middleName" type="text"
                      label={
                        t('MIDDLE')
                      }
                      variant="outlined"
                      fullWidth
                      size="normal"
                      focused
                      className="profileSettingTextInput"
                      value={middleName}
                      onChange={onMiddleNameChanged}
                      inputProps={
                        { readOnly: isProcessing,maxLength: 20 ,"data-testid":"middleName"}
                      }/>
                    <TextFieldComponent value={lastName}
                      id="lastName"
                      type="text"
                      label={
                        t('LAST')
                      }
                      variant="outlined"
                      fullWidth
                      size="normal"
                      focused
                      required
                      className="profileSettingTextInput"
                      onChange={onLastNameChanged}
                      inputProps={
                        { readOnly: isProcessing ,maxLength: 30,"data-testid":"lastName" }
                      }/>
                    <TextFieldComponent id="suffixName" type="text"
                      label={
                        t('SUFFIX')
                      }
                      variant="outlined"
                      fullWidth
                      size="normal"
                      focused
                      className="profileSettingTextInputSmall"
                      value={suffixName}
                      onChange={onSuffixNameChanged}
                      inputProps={
                        { readOnly: isProcessing ,maxLength: 5,"data-testid":"suffix"}
                      }/>
                  </Stack>
                </Grid>
              </Grid>

              <Grid container direction="row" justifyContent="center" alignItems="center" >
                <Grid item
                  xs={2}
                  >
                  <Typography className="profileSettingColorText">{t('EMAIL')}</Typography>
                </Grid>
                <Grid item
                  xs={10}
                  >
                  <TextFieldComponent value={email}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        backgroundColor: 'rgba(140, 210, 250, 0.3)',
                        color: 'rgba(0, 0, 0, 0.6)',
                      },
                      maxWidth:665,
                    }}
                    id="email"
                    type="email"
                    label={
                      t('EMAIL')
                    }
                    variant="outlined"
                    fullWidth
                    size="normal"
                    focused
                    required
                    className={"profileSettingReadOnlyTextInput"}
                    onChange={onEmailChanged}
                    inputProps={
                      { readOnly: true ,"data-testid":"email"}
                    }/>

                </Grid>
              </Grid>
              <Grid container direction="row" justifyContent="center" alignItems="center" sx={{marginBottom:'25px'}}>
                <Grid item xs={2}/>
                <Grid item
                  xs={10}
                  >
                    <Typography sx={{maxWidth: 665, marginTop: "0.3rem" }} className='profileSettingdislabledLable'>{t('EMAIL_CANNOT_BE_CHANGED')} </Typography>
                </Grid>
              </Grid>
              <Grid container direction="row" justifyContent="center" alignItems="center" >
                <Grid item
                  xs={2}
                  >
                  <Typography className="profileSettingColorText">{t('PHONE_NUMBER')}</Typography>
                </Grid>
                <Grid item
                  xs={10}
                  >
                  <TextFieldComponent
                    label={
                      t('PHONE_NUMBER')
                    }
                    variant="outlined"
                    fullWidth
                    sx={{maxWidth: 665}}
                    type="text"
                    size="normal"
                    focused
                    className="profileSettingTextPhoneNumberInput"
                    value={phoneNumber}
                    onChange={onPhoneNumberChanged}
                    inputProps={{
                      readOnly: isProcessing,
                      maxLength: 12,
                      "data-testid":"phone"
                    }}
                    InputProps={{
                     endAdornment: phoneNumber && !isPhoneNumber(phoneNumber) && <StyledTooltip title={t('INVALID_PHONE_NUMBER')} placement="right" />
                    }}
                    />
                </Grid>
              </Grid>

              <Grid container direction="row" justifyContent="center" alignItems="center" className="profileSettingGridHeight">
                <Grid item
                  xs={2}
                  />
                <Grid item
                  xs={10}
                  >
                  <Stack direction="row"
                  spacing={0}
                  justifyContent="flex-end"
                  alignItems="center"
                  sx={{maxWidth: 665,marginTop:'25px'}}>
                   {isMFAEnabled && <Button data-testid="manageMfaButton" className="profileSettingLbButtonColorText" onClick={manageMfaMethods} sx={{'&:hover': {backgroundColor:'transparent'}}} disabled={!isMFAEnabled}>
                    {t('MANAGE_MFA_METHODS')}
                  </Button>}
                </Stack>
                  <Stack direction="row"
                    spacing={2}
                    justifyContent="flex-end"
                    alignItems="center"
                    alignSelf="center"
                    sx={{maxWidth: 665,marginTop: forMobileDevice ? 0 : (isMFAEnabled?'25px':'50px')}}>

                    <Button data-testid="cancelProfileButton" variant="outlined" className="profileSettingButtonNormal profileSettingBottonCancel"
                      onClick={cancelSave}
                      disabled={isProcessing}>
                      <span className="profileSettingSpanNone">{t('CANCEL')}</span>
                    </Button>
                    <Button type='submit' data-testid="saveButton" variant="contained" className="profileSettingButtonNormal"
                      disabled={isProcessing || !(!!firstName && !!lastName && !!email) || (!!phoneNumber && !isPhoneNumber(phoneNumber))}>
                      {isProcessing
                        ? <CircularProgress size={'24px'} sx={{ p: '4px' }} />
                        : <span className="profileSettingSpanNone"> {t('SAVE')}</span>
                      }
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </form>
        </div>
      </div>
      { manageMFA && <MFAContainer setManageMFA={setManageMFA} _status={STATUS.MANAGE_MFA_METHODS} userInfo={userInfo}/>}
    </div>
  );
}

