export default function getBrowserLocales() {
  
    const opt = {
  
        languageCodeOnly: true,
  
    };
  
    const browserLocales =
  
      navigator.languages === undefined
  
        ? [navigator.language]
  
        : navigator.languages;
  
    if (!browserLocales) {
  
      return undefined;
  
    }
  
    return browserLocales.map(locale => {
  
      const trimmedLocale = locale.trim();
  
      return opt.languageCodeOnly
  
        ? trimmedLocale.split(/-|_/)[0]
  
        : trimmedLocale;
  
    });
  
  }