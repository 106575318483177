import React, {useState } from 'react';
import { Button, CircularProgress, Link, Stack } from '@mui/material';
import validator from "validator";
import _ from 'lodash';
import CssTextField from '../../../components/CssTextField';
import './ForgotPassword.scss';
import { STATUS } from '../../../config/loginStatus';
import passwordService from '../../../services/passwordService';
import { useTranslation } from 'react-i18next';
import StyledTooltip from '../../../components/common/StyledTooltip';
import config from '../../../config';

export default function ForgotPassword({ onStatusChanged, showToastMessage, setResetEmail, onCancel}) {

  const [email, setEmail] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const {t}=useTranslation();

  const onEmailedChanged = (event) => {
    setEmail(event.target.value);
    showToastMessage('');
  }
  const validateEmail = (value) => {
    return validator.isEmail(value);
  }

  const handleForgotPassword = async() => {

    setIsProcessing(true);
    try {
      let endpoint="/sso/v1/forgotpassword"
      const response = await passwordService.forgotPassword(config.ssoApiBaseUrl, endpoint, email);
      if (response && response.data && !(response.data instanceof Error)) {
        if(response.data?.status===200){
          localStorage.setItem('sid',response.data?.data?.sid);
          setResetEmail(email)
          onStatusChanged(STATUS.RESET_PASSWORDD);
        }
      } else if(response?.data?.response?.data ) {
          showToastMessage(t('EMAIL_NOT_FOUND'));
      } else {
          showToastMessage(t('AN_UNEXPECTED_ERROR_HAS_OCCURRED'));
      }
      setIsProcessing(false);
    } catch (ex) {
      showToastMessage(t('AN_UNEXPECTED_ERROR_HAS_OCCURRED'));
      setIsProcessing(false);
    }
  }

  const onContinueClicked = (event) => {
    event.preventDefault();
    if (validateEmail(email)) {
      handleForgotPassword();
    }
  }

  return <div className='form-container'>
      <form className="form" onSubmit={onContinueClicked}>
        <div className='label Roboto-font-400'>
          <span>{t('FORGOT_PASSWORD_HEADER_LABEL')}</span>
        </div>
        <CssTextField required id='outlined-basic' label={t('EMAIL')} variant='outlined' fullWidth size='normal' focused autoFocus={true}
          value={email}
          className='textField'
          onChange={onEmailedChanged}
          InputProps={{
            endAdornment: email && !validateEmail(email) && <StyledTooltip title={t('INVALID_EMAIL_FORMAT')} placement="right" />
          }}
          inputProps={{ "data-testid": "email" }}

        />

        <Button data-testid="button" type='submit' variant='contained' fullWidth className='button-text'
          disabled={!validateEmail(email) || isProcessing}> {isProcessing ? <CircularProgress sx={{p:'4px'}}/> : t('CONTINUE')}</Button>
         <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            marginTop="24px"
           >
            <Link href="#" className="cancel-link" underline="always" onClick={() => onCancel(STATUS.SIGN_IN)}>{t('CANCEL')}</Link>
          </Stack>  
      </form>
  </div>
}
