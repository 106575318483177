import React, { useState } from "react";
import { STATUS } from "../../../config/loginStatus";
import ToastMessage, { MESSAGE_TYPE } from "../../../components/ToastMessage";
import { useTranslation } from 'react-i18next';
import MFADevicesList from "./MFADevicesList";
import authenticationService from "../../../services/authenticationService";
import MfaSignInCodeLoginFlow from "./MfaSignInCodeLoginFlow";

export default function MFAContainerLoginFlow({ userInfo, onLoginSuccess, mfaDeviceList, onLoginFailed, onCancel, _status = STATUS.SIGN_IN }) {

    const [status, setStatus] = useState(_status);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('error');
    const [isLoading] = useState(false);
    const [selectedDevice, setSelectedDevice] = useState();

    const { t } = useTranslation(); 

    const onDeviceSelection = (device) => {
        setSelectedDevice(device);
        authenticationService.sendOTP("/sso/v1/login/device", device?.id).then(response => {
             if(response.data && !(response.data instanceof Error)){
              if(device.type === 'EMAIL') {
                setStatus(STATUS.AUTHENTICATION_THROUGH_EMAIL_LOGIN);  
              } else
              if(device.type === 'SMS') {
                setStatus(STATUS.AUTHENTICATION_THROUGH_SMS_LOGIN);
              } else
              if(device.type === 'TOTP') {
                setStatus(STATUS.AUTHENTICATION_THROUGH_APPQR_LOGIN);
              } 
            } else {
              if(response?.data?.response?.data?.error?.[0]?.code === "NOT_FOUND"){
                onLoginFailed();
              }
              else{
                showToastMessage(t("AN_UNEXPECTED_ERROR_HAS_OCCURRED"));
              }
            }
        })
        .catch(() => {         
            showToastMessage(t("AN_UNEXPECTED_ERROR_HAS_OCCURRED"));
        });   
    }

    const changeStatus = (_status) => {
      setStatus(_status);      
    }

    const showToastMessage = (message, type = MESSAGE_TYPE.ERROR) => {
      setMessage(message);
      setMessageType(type);
    }

    const handleClose = () => {
      setMessage('');
    }  

    const renderComponent = (_status) => {      
      switch (_status) {
        case STATUS.MFA_DEVICES_LIST_LOGIN:
          return <MFADevicesList
            mfaDeviceList={mfaDeviceList}
            isLoading={isLoading}
            onDeviceSelection={onDeviceSelection}
           />;     

        case STATUS.AUTHENTICATION_THROUGH_EMAIL_LOGIN:
            return <MfaSignInCodeLoginFlow
              onStatusChanged={changeStatus}
              authMethod={STATUS.AUTHENTICATION_THROUGH_EMAIL_LOGIN}
              showToastMessage={showToastMessage}
              userInfo={userInfo}
              onLoginSuccess={onLoginSuccess}          
              selectedDevice={selectedDevice}
              onLoginFailed={onLoginFailed} 
              />;   

      case STATUS.AUTHENTICATION_THROUGH_SMS_LOGIN:
              return <MfaSignInCodeLoginFlow
                userInfo={userInfo}
                onStatusChanged={changeStatus}
                authMethod={STATUS.AUTHENTICATION_THROUGH_SMS_LOGIN}
                onLoginSuccess={onLoginSuccess}
                showToastMessage={showToastMessage}
                selectedDevice={selectedDevice}
                onLoginFailed={onLoginFailed} 
                />;

      case STATUS.AUTHENTICATION_THROUGH_APPQR_LOGIN:
              return <MfaSignInCodeLoginFlow
                onStatusChanged={changeStatus}
                authMethod={STATUS.AUTHENTICATION_THROUGH_APPQR_LOGIN}
                showToastMessage={showToastMessage}
                userInfo={userInfo}
                onLoginSuccess={onLoginSuccess}
                selectedDevice={selectedDevice}
                onLoginFailed={onLoginFailed} 
               />;     

      case STATUS.MFA_CODE_ON_EMAIL_LOGIN:
          return <MfaSignInCodeLoginFlow
            userInfo={userInfo}
            onStatusChanged={changeStatus}
            authMethod={STATUS.AUTHENTICATION_THROUGH_EMAIL_LOGIN}
            showToastMessage={showToastMessage}
            onLoginSuccess={onLoginSuccess}
            onLoginFailed={onLoginFailed} 
            onCancel={onCancel}
            />;
        default:
          return <></>;
      }
    }

    return <div>
      <ToastMessage message={message} type={messageType} onClose={handleClose} />
      {renderComponent(status)}
    </div>
}
