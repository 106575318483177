
import config from "../config";
import { MFA_DEVICE_TYPE, MFA_STATUS } from "../config/mfaTypes";
import MfaMethod from "../Model/MfaMethod";
import httpModule from "../utils/httpModule";

/**
 * Read All MFA User Devices
 **/
const getMfaMethods = async () => {
  const http = httpModule(config.ssoApiBaseUrl);
  const apiPath = '/sso/v1/user/devices';
  const headers = {
    'sid': localStorage.getItem('sid')
  }
  try {
    const response = await http.get(apiPath, {}, headers);
    if (response?.data && !(response.data instanceof Error)) {
      // convert to model
      const devices =  response?.data?.data?.devices?.map((device) => convertToMfaMethodModal(device)).filter((device) => device.status === MFA_STATUS.ACTIVE);
      const maxAllowedDevices = response?.data?.data?.maxAllowedDevices ?? 0;
      return {
        devices,
        maxAllowedDevices,
        allowedTypes: response?.data?.data?.allowedtypes ?? [],
      }
    } else {
      return response?.data;
    }
  } catch (ex) {
    return ex;
  };
};

/**
 * Request add new MFA method
 *  @param userId user id
 * @param type MFA device type: SMS, TOTP, etc.
 */
const requestAddNewMfaMethod = async (type, device) => {
  const http = httpModule(config.ssoApiBaseUrl);
  const apiPath = '/sso/v1/mfa/devices';
  const headers = {
    'sid': localStorage.getItem('sid')
  }
  const body = { type };
  if (device) {
    body.device = device;
  }
  try {
    const response = await http.post(apiPath, body, {}, headers);
    return response?.data;
  } catch (ex) {
    return ex;
  };
};

/**
 * Confirm add MFA method
 *  @param userId user id
 * @param type MFA device type: SMS, TOTP, etc.
 */
const confirmAddMfaMethod = async (deviceId, type, code) => {
  const http = httpModule(config.ssoApiBaseUrl);
  const apiPath = `/sso/v1/mfa/devices/${deviceId}`;
  const headers = {
    'sid': localStorage.getItem('sid')
  }
  try {
    const response = await http.post(apiPath, {type, code}, {}, headers);
    if (response?.data && !(response.data instanceof Error)) {
      return convertToMfaMethodModal(response?.data?.data);
    } else {
      return response?.data;
    }
  } catch (ex) {
    return ex;
  };
};
/**
 * Delete MFA User Device
 * @param apiPath API path
 * {{apiPath}}/environments/{{envID}}/users/{{userID}}/devices/{{deviceID}}
 */
const deleteMfaUserDevice = async (deviceId) => {
  const http = httpModule(config.ssoApiBaseUrl);
  const apiPath = `/sso/v1/mfa/${deviceId}`;
  const headers = {
    'sid': localStorage.getItem('sid')
  }
  try {
    const response = await http.delete(apiPath, headers);
    return response;
  } catch (ex) {
    return ex;
  };
};

const convertToMfaMethodModal = (device) => {
  console.log('convertToMfaMethodModal: ', device);
  const mfaMethod = MfaMethod();
  mfaMethod.id = device.id;
  mfaMethod.status = device.status;
  mfaMethod.type = device.type;
  mfaMethod.nickname = hideInfo(device);
  switch (device.type) {
    case MFA_DEVICE_TYPE.EMAIL:
      mfaMethod.value = device.email;
      break;
    case MFA_DEVICE_TYPE.SMS:
    case MFA_DEVICE_TYPE.VOICE:
      mfaMethod.value = device.phone;
      break;
    case MFA_DEVICE_TYPE.TOTP:
      mfaMethod.value = '';
      break;
    case MFA_DEVICE_TYPE.PLATFORM:
      mfaMethod.value = device.platform;
      break;
    default:
      mfaMethod.value = '';
      break;
  }
  return mfaMethod;
};

/**
 * Get display type name base on type, using for showing on UI
 * @param {*} type MFA_DEVICE_TYPE;
 */
const getMfaTypeName = (type) => {
  switch (type) {
    case MFA_DEVICE_TYPE.EMAIL: return 'Email';
    case MFA_DEVICE_TYPE.SMS: return 'Text Message';
    case MFA_DEVICE_TYPE.VOICE: return 'Phone Call';
    case MFA_DEVICE_TYPE.TOTP: return 'Authenticator App';
    case MFA_DEVICE_TYPE.PLATFORM: return 'Platform Authenticator';
    default:
      return type;
  }
}

const hideInfo = (item) => {
  let arr = null;
  let str = null;
  if (item.type.toUpperCase() === MFA_DEVICE_TYPE.EMAIL) {
    arr = item.email.split('@');
    str = item.email[0] + '***' + arr[0].slice(arr[0].length - 1, arr[0].length) + '@' + arr[1];
    return str;
  } else if (item.type.toUpperCase() === MFA_DEVICE_TYPE.SMS || item.type.toUpperCase() === MFA_DEVICE_TYPE.VOICE) {
    if (item.phone) {
      let strTemp = item.phone.replaceAll('-', '').replace('+','');
      str = strTemp[0] + strTemp[1] + '****' + strTemp.slice(strTemp.length - 4, strTemp.length);
    }
    return str;
  } else if (item.type.toUpperCase() === MFA_DEVICE_TYPE.TOTP) {
    return item.nickname || 'Authenticator App';
  }
  else {
    return item.nickname ?? '';
  }
}

const generateTotpUri = (email, secret) => {
  return `otpauth://totp/${email}?secret=${secret}`;
}

const mfaService = {
  getMfaMethods,
  getMfaTypeName,
  deleteMfaUserDevice,
  requestAddNewMfaMethod,
  confirmAddMfaMethod,
  generateTotpUri,
}

export default mfaService;
