import React, {useState } from "react";
import "./RemoveRegisteredMFAMethod.scss";
import {
  Button,
  Box,
  Stack,
  Modal,
  CircularProgress,
} from "@mui/material";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import TabletMacOutlinedIcon from "@mui/icons-material/TabletMacOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import { useTranslation } from "react-i18next";
import { STATUS } from "../../../config/loginStatus";
import AlertDialog from "../../../components/common/AlertDialog";
import { MFA_DEVICE_TYPE } from "../../../config/mfaTypes";
import mfaService from "../../../services/mfaService";
import CircularLoading from "../../../components/CircularLoading";
import CrossIcon from "../../../components/common/CrossIcon";

export default function ManageMFAMethods({
  isOpenPopupMFAMethod,
  onPopupMFAMethodClose,
  onRemoveMFAMethod,
  arrRegisteredMFAMethod,
  onStatusChanged,
  authMethodsAdded,
  isLoading,
  maxAllowedDevices,
}) {

  const { t } = useTranslation();
  const [openAlert, setOpenAlert] = useState(false);
  const [currentIndex, setCurrentIndex] = useState();


  const onBtnClicked = () => {
    onStatusChanged(STATUS.SELECT_AUTHENTICATION_METHOD);
  }

  const onConfirm = () => {
    setOpenAlert(false);
    onRemoveMFAMethod(currentIndex);
  }

  const getMfaIcon =(type) => {
    switch (type) {
      case MFA_DEVICE_TYPE.EMAIL:
        return <MailOutlineOutlinedIcon color="primary" fontSize="large" sx={{ color: 'rgba(45, 160, 235, 1)', width: "40px" }} />;
      case MFA_DEVICE_TYPE.SMS:
        return <ChatOutlinedIcon color="primary" fontSize="large" sx={{ color: 'rgba(45, 160, 235, 1)', width: "40px" }} />;
      case MFA_DEVICE_TYPE.VOICE:
        return <PhoneInTalkIcon color="primary" fontSize="large" sx={{ color: 'rgba(45, 160, 235, 1)', width: "40px" }} />;
      case MFA_DEVICE_TYPE.TOTP:
        return <TabletMacOutlinedIcon color="primary" fontSize="large" sx={{ color: 'rgba(45, 160, 235, 1)', width: "40px" }} />;
      default:
        return <TabletMacOutlinedIcon color="primary" fontSize="large" sx={{ color: 'rgba(45, 160, 235, 1)', width: "40px" }} />;
    }
  }
  const listRegisteredMFAMethod = (item, index, ar) => {
    return (
      <ListItem
        disablePadding
        onClick={() => null}
        key={"RegisteredMFAMethod" + index}
      >
        <ListItemButton>
          <ListItemIcon>
            {getMfaIcon(item.type)}
          </ListItemIcon>
          <ListItemText
            primary={mfaService.getMfaTypeName(item.type)}
            secondary={
              <span className="RemoveMFAMethodPopupLable">
                {item.nickname}
              </span>
            }
          />
          {(item.type !== MFA_DEVICE_TYPE.EMAIL) &&
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                variant="outlined"
                className="RemoveMFAMethodPopupButtonNormal"
                onClick={() => { setCurrentIndex(index); setOpenAlert(true); }}
              >
                <span className="RemoveMFAMethodPopupSpanBT">Remove</span>
              </Button>
            </Stack>
          }
        </ListItemButton>
      </ListItem>
    );
  }
  return (
    <div>
      <Modal
        open={isOpenPopupMFAMethod}
        onClose={onPopupMFAMethodClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box className="RemoveMFAMethodPopupContainer">
        <Stack
          direction="row"
          justifyContent="right"
          alignItems="right"
          ><CrossIcon fontSize='small' onClose={() => onPopupMFAMethodClose(false)}/></Stack>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            marginBottom="10px"
          >
            <span className="RemoveMFAMethodPopupHeader">
              Manage your MFA methods below.
            </span>
          </Stack>
          {isLoading ? <CircularLoading />:
            <List>
                {arrRegisteredMFAMethod &&
                  arrRegisteredMFAMethod.length > 0 &&
                  arrRegisteredMFAMethod.map(listRegisteredMFAMethod)}
            </List>
          }
          <div style={{ height: "20px" }}></div>
          <Button sx={{textTransform:'none'}} disabled={(maxAllowedDevices > 0 && arrRegisteredMFAMethod.length >= maxAllowedDevices)} type='submit' variant="contained" fullWidth className='rpw-button-text' onClick={onBtnClicked} style={{ marginTop: '20px' }}>
              {t('ADD_ANOTHER_AUTHENTICATION_METHOD')}
          </Button>
        </Box>
      </Modal>
      <AlertDialog
          open={openAlert}
          title={t('REMOVE_MFA_METHOD')}
          message={t('ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THE_SELECTED_MFA_METHOD')}
          cancelLabel={t('GO_BACK')}
          confirmLabel={t('REMOVE')}
          onCancel={() => setOpenAlert(false)}
          onConfirm={onConfirm}
      />
    </div>
  );
}
