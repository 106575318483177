import { Box, CircularProgress, Stack } from '@mui/material';
import React from 'react';

const LandingPageLoading = () => {
  return <Box className="landingPageCover">
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
      ><img className='imgLogoSurepoint' src="/SurePoint_Logo_Trademark.svg" />
      </Stack>

      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
      ><CircularProgress sx={{ p: '4px' }} />
      </Stack>
    </Box>
};

export default LandingPageLoading;