import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import config from '../config';

const requestInProgress = {};
/**
 * Wrapper around axios, enabling ease service creation.
 *
 * @param {*} baseUrl
 * @param {*} token
 * @returns An object wrapping basic Rest methods {get, post, delete, put}
 */
const httpModule = (baseUrl, sid, logger = null) => {
  const getApiAuthHeader = () => {
    if (sid) {
      return {
        sid: `${sid}`,
      };
    }
    return {};
  };
  // Custom helper function to build api headers
  const buildHeaders = (customHeaders = {}, token = null) => ({
    ...getApiAuthHeader(),
    ...customHeaders,
  });

  // Function that will be called to refresh authorization
  const refreshAuthLogic = async (failedRequest) => {
    // AWS-SDK helper
    // const aws = awsHelper();
    try {
      //const { idToken, accessToken } = await aws.refreshSessionPromise();
      // TODO: call refresh function here
      // failedRequest.response.config.headers.Authorization = 'Bearer ' + accessToken;
      //failedRequest.response.config.headers.AccessToken = 'Bearer ' + accessToken;
      console.log("SSO: User logged out due to 401/403 response status code");          
      localStorage.removeItem("isAuthorized");
      window.location.replace(config.redirectUri+"?isAuthorized=false");
      return Promise.resolve();
    } catch (ex) {
      logger && logger.log(ex);
    }
  };
  const initAuthRefreshInterceptor = () => {
    createAuthRefreshInterceptor(
      axios,
      refreshAuthLogic,
      {
        statusCodes: [401, 403],
        pauseInstanceWhileRefreshing: true,
      },
    );
  }

  function finalFetch(_config) {
    return axios(_config)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          return {
            data: { isCancel: true },
          };
        } else {
          return { data: error };
        }
      })
      .finally(() => {
        if(!_config?.url?.includes('logout')){
          console.log(_config);
          sessionStorage.setItem('timeStamp',Date.now());
          window.dispatchEvent(new Event('storage'))
        }
       });
  }

  const fetchCancelable = async (
    endpoint,
    params,
    data,
    type = 'get',
    endpointName = null,
    cancelPreviousRequest = true,
    blockIfAlreadyInProgress = false,
    customHeaders = {},
    baseurl = baseUrl,
  ) => {
    initAuthRefreshInterceptor();
    const CancelToken = axios.CancelToken;
    if (
      cancelPreviousRequest &&
      endpointName &&
      requestInProgress[endpointName] &&
      typeof requestInProgress[endpointName] === 'function'
    ) {
      // cancel the request if the request is already in progress
      requestInProgress[endpointName]();
    }
    if (blockIfAlreadyInProgress) {
      Promise.reject({code: 1, error: 'request already in progress'});
    }
    const _config = {
      method: type,
      withCredentials: true,
      url: endpoint,
      baseURL: baseurl,
      params,
      data: data,
      headers: buildHeaders(customHeaders),
      cancelToken: new CancelToken(function executor(c) {
        endpointName && (requestInProgress[endpointName] = c);
      }),
    };
    return await finalFetch(_config);
  }
  axios.defaults.withCredentials = true
  // An object with wrapping basic Rest methods
  return {
    // GET Method
    get: async (endPoint, params, customHeaders = {}) => {
      return fetchCancelable(endPoint, params, null, 'get', endPoint, true, false, customHeaders);
    },
    // PUT Method
    put: async (endPoint, data, params, customHeaders = {}) => {
      return fetchCancelable(endPoint, params, data, 'put', endPoint, true, false, customHeaders);
    },
    // POST Method
    post: async (endPoint, data, params, customHeaders = {}) => {
      return fetchCancelable(endPoint, params, data, 'post', endPoint, true, false, customHeaders);
    },
    // DELETE Method
    delete: async (endPoint, customHeaders = {}) => {
      return fetchCancelable(endPoint, null, null, 'delete', endPoint, true, false, customHeaders);
    },
    // DELETE Method with body data
    deleteWithData: async (endPoint, data, customHeaders = {}) => {
      return fetchCancelable(endPoint, null, data, 'delete', endPoint, true, false, customHeaders);
    },
  };
};

export default httpModule;
