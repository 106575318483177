import React, { useState, useEffect } from "react";
import MfaSignInCode from "./MfaSignInCode";
import { STATUS } from "../../../config/loginStatus";
import ToastMessage, { MESSAGE_TYPE } from "../../../components/ToastMessage";
import ManageMFAMethods from "./ManageMFAMethods";
import AuthenticationInput from "./AuthenticationInput";
import SelectAuthenticationMethod from "./SelectAuthenticationMethod";
import mfaService from "../../../services/mfaService";

import { useTranslation } from 'react-i18next';
import { MFA_STATUS } from "../../../config/mfaTypes";

export default function MFAContainer({ userInfo: _parentUser, setManageMFA, _status = STATUS.SIGN_IN}) {

    const [status, setStatus] = useState(_status);
    const [authMethod, setAuthMethod] = useState();
    const [message, setMessage] = useState('');
    const [userInfo, setUserInfo] = useState(_parentUser);
    const [messageType, setMessageType] = useState('error');
    const [isOpenPopupMFAMethod, setIsOpenPopupMFAMethod] = useState(true);
    const [arrRegisteredMFAMethod, setArrRegisteredMFAMethod] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [maxAllowedDevices, setMaxAllowedDevices] = useState(5);
    // const [allowedTypes, setAllowedTypes] = useState([]);

    const { t } = useTranslation();
    useEffect(() => {
      setIsLoading(true);
      mfaService.getMfaMethods().then((response) => {
        if (response && ! (response instanceof Error)) {
          setArrRegisteredMFAMethod(response.devices ?? []);
          setMaxAllowedDevices(response.maxAllowedDevices);
          // setAllowedTypes(response.allowedTypes);
        }
      }).finally(() => setIsLoading(false));
    }, [])

    const changeStatus = (_status) => {
      setStatus(_status);
    }

    const showToastMessage = (message, type = MESSAGE_TYPE.ERROR) => {
      setMessage(message);
      setMessageType(type);
    }

    const handleClose = () => {
      setMessage('');
    }

    const onAddAuthenticationMethod = (device) => {
      if (device.status === MFA_STATUS.ACTIVE) {
        setArrRegisteredMFAMethod([...new Set([...arrRegisteredMFAMethod, device])])
      }
    }

    const onEmailOrPhoneNumberChange = (value) => {
      setUserInfo({...userInfo, mfaDevice: {...value}});
    }

  const onPopupMFAMethodClose = () =>{
    setIsOpenPopupMFAMethod(false);
    setManageMFA(false);
  }

  const onRemoveMFAMethod = (indexRemove) =>{
    onDeleteMFADeviceUserItem(indexRemove);
  }

  const onDeleteMFADeviceUserItem = (indexRemove) =>{
    const cloneArrMFAMethodTemp = [...arrRegisteredMFAMethod];
    const removeDeviceArrItem=cloneArrMFAMethodTemp.splice(indexRemove, 1);
    mfaService.deleteMfaUserDevice(removeDeviceArrItem[0].id).then((response) => {
      if (response && ! (response instanceof Error) && (response.status===200 || response.status===204)) {
        showToastMessage(t('MFA_METHOD_HAS_BEEN_REMOVED'), MESSAGE_TYPE.SUCCESS);
        arrRegisteredMFAMethod.splice(indexRemove, 1);
      }else if (response && !(response instanceof Error) && response.data && response.data.response && response.data.response.status!==200  ){
          if (response.data.response.data.code==='INVALID_REQUEST'){
            showToastMessage(response.data.response.data.message);
          }else if (response.data.response.data.code){
            showToastMessage(response.data.response.data.message);
          }else{
            showToastMessage(t('AN_UNEXPECTED_ERROR_HAS_OCCURRED'));
          }
      }else{
        showToastMessage(t('AN_UNEXPECTED_ERROR_HAS_OCCURRED'));
      }
    }).catch((ex) => {
      showToastMessage(t('AN_UNEXPECTED_ERROR_HAS_OCCURRED'));
    });
  }
  const propsTempMFA={
      onPopupMFAMethodClose,
      onRemoveMFAMethod,
      arrRegisteredMFAMethod,
      isOpenPopupMFAMethod,
      isLoading,
      maxAllowedDevices,
  };

    const renderComponent = (_status) => {
      switch (_status) {
        case STATUS.MFA_SIGN_IN:
          return <MfaSignInCode onStatusChanged={changeStatus} setAuthMethod={setAuthMethod} authMethod={authMethod} showToastMessage={showToastMessage}/>;
        case STATUS.SELECT_AUTHENTICATION_METHOD:
          return <SelectAuthenticationMethod onStatusChanged={changeStatus} userInfo={userInfo} registeredMfaMethods={arrRegisteredMFAMethod} onPopupMFAMethodClose={onPopupMFAMethodClose}/>;
        case STATUS.MANAGE_MFA_METHODS:
          return <ManageMFAMethods
            userInfo={userInfo}
            setAuthMethod={setAuthMethod}
            authMethod={authMethod}
            onStatusChanged={changeStatus}
            {...propsTempMFA}/>;
        case STATUS.AUTHENTICATION_THROUGH_EMAIL:
          return <AuthenticationInput
            userInfo={userInfo}
            onStatusChanged={changeStatus}
            authMethod={STATUS.AUTHENTICATION_THROUGH_EMAIL}
            onEmailOrPhoneNumberChange={onEmailOrPhoneNumberChange}
            showToastMessage={showToastMessage} />;
        case STATUS.AUTHENTICATION_THROUGH_SMS:
          return <AuthenticationInput
            onStatusChanged={changeStatus} authMethod={STATUS.AUTHENTICATION_THROUGH_SMS}
            onEmailOrPhoneNumberChange={onEmailOrPhoneNumberChange}
            userInfo={userInfo}
            registeredMfaMethods={arrRegisteredMFAMethod}
            onPopupMFAMethodClose={onPopupMFAMethodClose}
            showToastMessage={showToastMessage}/>;
        case STATUS.AUTHENTICATION_THROUGH_APPQR:
          return <MfaSignInCode
            onStatusChanged={changeStatus}
            onAddAuthenticationMethod={onAddAuthenticationMethod}
            authMethod={STATUS.AUTHENTICATION_THROUGH_APPQR}
            showToastMessage={showToastMessage}
            userInfo={userInfo}
            registeredMfaMethods={arrRegisteredMFAMethod}
            onPopupMFAMethodClose={onPopupMFAMethodClose}/>;
        case STATUS.MFA_CODE_ON_PHONE:
          return <MfaSignInCode
            userInfo={userInfo}
            onStatusChanged={changeStatus}
            onAddAuthenticationMethod={onAddAuthenticationMethod}
            authMethod={STATUS.AUTHENTICATION_THROUGH_SMS}
            showToastMessage={showToastMessage}/>;
        case STATUS.MFA_CODE_ON_EMAIL:
          return <MfaSignInCode
            userInfo={userInfo}
            onStatusChanged={changeStatus}
            onAddAuthenticationMethod={onAddAuthenticationMethod}
            authMethod={STATUS.AUTHENTICATION_THROUGH_EMAIL}
            showToastMessage={showToastMessage}/>;
        default:
          return <></>;
      }
    }

    return <div>
      <ToastMessage message={message} type={messageType} onClose={handleClose} />
      {renderComponent(status)}
    </div>
}
