import React, {useState} from 'react';
import _ from 'lodash';
import { CircularProgress, Link, Stack, Typography } from "@mui/material";

import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import CssTextField from '../../../components/CssTextField';
import './ResetPassword.scss';
import { passwordRequirementsValidator, getServerValidatedRequirementMessage, getPasswordPolicyList, defaultPasswordPolicy } from '../../../utils/passwordValidate';
import passwordService from '../../../services/passwordService';
import { useTranslation } from 'react-i18next';
import StyledButton from '../../../components/common/StyledButton';
import StyledTooltip from '../../../components/common/StyledTooltip';
import config from '../../../config';
import { RESPONSE_STATUS, STATUS } from '../../../config/loginStatus';
import PasswordPolicy from '../../../components/common/PasswordPolicy';
import { MESSAGE_TYPE } from '../../../components/ToastMessage';

export default function ResetPassword({flow,setMfaDeviceList, onStatusChanged, showToastMessage,resetEmail,onEmailOrPhoneNumberChange,onLoginSuccess,onLoginFailed, onCancel}) {
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPasswordFocused, setNewPasswordFocused] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  //show password
  const [showNewPassword, setShowNewPassword] = useState(false);
  const onClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const onClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
  const onMouseDownPassword = (event) => {
      event.preventDefault();
  };
  //end showpassword

  const { t } = useTranslation();
  const onCodeChanged = (event) => {
      setCode(event.target.value);
  }
  const resetStatusAndError = () => {
    showToastMessage('');
  }

  const onPasswordChanged = (event) => {
      setPassword(event.target.value);
      resetStatusAndError();
  }
  const showTooltipReqs = () => {
    setNewPasswordFocused(true);
  }
  const hideTooltipReqs = () => {
    setNewPasswordFocused(false);
  }

  const onConfirmPasswordChanged = (event) => {
      setConfirmPassword(event.target.value);
      resetStatusAndError();
  }

  const resendCode = (_resetEmail) => {
    setIsProcessing(true);
    resetStatusAndError();
    let endpoint="/sso/v1/forgotpassword"
    passwordService.forgotPassword(config.ssoApiBaseUrl, endpoint,_resetEmail)
    .then((response) => {
      if (response && response.data && !(response.data instanceof Error)) {
        localStorage.setItem('sid',response.data?.data?.sid)
        showToastMessage(t('AUTHENTICATION_CODE_SENT'), MESSAGE_TYPE.SUCCESS)
      } else {
        if(response?.data?.response?.data?.error?.[0]?.code === "NOT_FOUND"){
          onLoginFailed();
        }
        else{ 
          showToastMessage(t('UNABLE_TO_RESEND_CODE'));
        }
      }
      setIsProcessing(false);
    })
    .catch((err) => {
      showToastMessage(t('UNABLE_TO_RESEND_CODE'));
      setIsProcessing(false);
    });
  }

  const parseError = (err) => {
    let errorMsg = '';
    const errorCode = err.error[0]?.code;

    if (errorCode === 'INVALID_DATA') {
      const detailError = err.error[0]?.details[0];
      if (detailError && detailError?.code === 'INVALID_VALUE') {
        const errorTarget = detailError.target;
         if (errorTarget === 'recoveryCode') {
          errorMsg = t('INVALID_AUTHENTICATION_CODE');
        } else if (errorTarget === 'newPassword') {
          const unsatisfiedServerRequirements = detailError.innerError?.unsatisfiedRequirements;
          if (unsatisfiedServerRequirements) {
            // if there are multiple server validation fails, show just one
            const failedReq = unsatisfiedServerRequirements[0];
            errorMsg = getServerValidatedRequirementMessage(failedReq, defaultPasswordPolicy, t);
          } else {
            errorMsg = detailError.message ?? t('AN_UNEXPECTED_ERROR_HAS_OCCURRED');
          }
        } else {
          // Edge case where the error detail is INVALID_VALUE, but it's not a value we expect. Should never happen unless an API change happens without updating the UI.
          errorMsg = t('AN_UNEXPECTED_ERROR_HAS_OCCURRED');
        }
      }
    } else {
      // Edge case where the error detail not INVALID_VALUE. Should never happen unless an API change happens without updating the UI.
      errorMsg = t('AN_UNEXPECTED_ERROR_HAS_OCCURRED');
    }
    if (errorMsg) {
      showToastMessage(errorMsg);
    }
  }

  const resetPasswordWithCode = async () => {
    setIsProcessing(true);
    resetStatusAndError();
    let endpoint="/sso/v1/forgotpassword/validatecode"
    try {
      const response = await passwordService.resetPassword(config.ssoApiBaseUrl,endpoint, code, password,localStorage.getItem('sid'));
      if (response && response.data && !(response.data instanceof Error) && response.data?.status===200) {
          showToastMessage(t('PASSWORD_CHANGED'), 'success');
          localStorage.setItem('sid',response.data?.data?.sid);
          setMfaDeviceList(response.data?.data?.devices ?? []);
          localStorage.setItem('emaildeviceid',JSON.stringify(response.data?.data?.devices));
          if(response.data?.data?.mfaStatus === RESPONSE_STATUS.OTP_REQUIRED){
            localStorage.setItem('sid',response.data?.data?.sid);
            if(response.data.data?.devices.findIndex((device)=>device.type==="EMAIL")!==-1){
                localStorage.setItem('sid',response.data?.data?.sid);
                onStatusChanged(STATUS.MFA_CODE_ON_EMAIL_LOGIN);
                onEmailOrPhoneNumberChange({ userEmail: resetEmail, userPassword: password});
            }
            else if(response.data.data?.devices.findIndex((device)=>device.type==="SMS")!==-1){
                localStorage.setItem('sid',response.data?.data?.sid);
                onStatusChanged(STATUS.MFA_CODE_ON_PHONE_LOGIN)
            }
          }
          else if(response.data?.data?.mfaStatus === RESPONSE_STATUS.DEVICE_SELECTION_REQUIRED){
            localStorage.setItem('sid',response.data?.data?.sid);
            onStatusChanged(STATUS.MFA_DEVICES_LIST_LOGIN);
            onEmailOrPhoneNumberChange({ userEmail: resetEmail, userPassword: password});
          }
           else{
             onLoginSuccess(response.data?.data?.sid,response.data?.data?.timeOut);
        }
        
      } else if(response?.data?.response?.data){
        if(response?.data?.response?.data?.error?.[0]?.code === "NOT_FOUND"){
          onLoginFailed();
        }
        else{
          parseError(response?.data?.response?.data);
          console.log(response?.data?.response?.data)
        }  
      } else {
        showToastMessage(t('AN_UNEXPECTED_ERROR_HAS_OCCURRED'));
      }
      setIsProcessing(false);
    } catch(ex) {
      showToastMessage(t('AN_UNEXPECTED_ERROR_HAS_OCCURRED'));
      setIsProcessing(false);
    }
  }

  const onBtnClicked = (event) => {
    event.preventDefault();
      if (code && password && confirmPassword && confirmPassword === password) {
        resetPasswordWithCode();
      }
  }
  const clientValidatedRequirements = passwordRequirementsValidator(defaultPasswordPolicy, password);
  const requirementsMet = !!password && _.reduce(clientValidatedRequirements, (result, req) => result && req.isValid, true);
  const doPasswordsDiffer = confirmPassword && !_.isEqual(password, confirmPassword);

  const typoStyle = {
    textAlign: 'center',
    paddingBottom: '1.5rem',
    color: '#000000'
  };

  return <div className='form-container'>
    <form data-testid="form"className="form" onSubmit={onBtnClicked}>
    <Typography sx={{ ...typoStyle }}>{t('RESET_PASSWORD_HEADER_LABEL')}</Typography>
      <CssTextField required id="txt-code" label={t('CODE')} variant="outlined" fullWidth size="normal" focused autoFocus={true}
      className='textField'
      inputProps={{'data-testid':'code'}}
      value={code}
      onChange={onCodeChanged}
      />
      <CssTextField required id="txt-new-password" 
        type={showNewPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: <>
                <InputAdornment position="end">
                  <IconButton
                      aria-label="toggle password visibility"
                      onClick={onClickShowNewPassword}
                      onMouseDown={onMouseDownPassword}
                      edge="end"
                      sx={[{color:'#0050C3'}, password && clientValidatedRequirements?.length > 0 && !requirementsMet? {marginRight:'5px'}: null]}
                  >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
                {
                (password && clientValidatedRequirements?.length > 0 && !requirementsMet)
                &&  <StyledTooltip title={<PasswordPolicy validateRequirements={clientValidatedRequirements} />} placement="right" />
                }
                
          </>
      }}
      inputProps={{'data-testid':'newPassword'}}
      label={t('NEW_PASSOWRD')} variant="outlined" fullWidth size="normal" focused
      className='textField'
      value={password}
      onChange={onPasswordChanged}
      onFocus={showTooltipReqs}
      onBlur={hideTooltipReqs}
      
      />
      <CssTextField required id="txt-confirm-password" 
        type={showConfirmPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: <>
                <InputAdornment position="end">
                  <IconButton
                      aria-label="toggle password visibility"
                      onClick={onClickShowConfirmPassword}
                      onMouseDown={onMouseDownPassword}
                      edge="end"
                      sx={[{color:'#0050C3'}, doPasswordsDiffer? {marginRight:'5px'}: null]}
                  >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
                {
                doPasswordsDiffer && <StyledTooltip title={t('PASSWORD_AND_CONFIRM_PASSWORD_DO_NOT_MATCH')} placement="right" />
                }
                
          </>
        }}
        inputProps={{'data-testid':'confirmPassword'}}
        label={t('CONFIRM_PASSWORD')} variant="outlined" fullWidth size="normal" focused
        className='textField'
        value={confirmPassword}
        onChange={onConfirmPasswordChanged}
      
      />
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        marginBottom="0.8rem"
      >
          <Link href="#" underline="none" onClick={()=>resendCode(resetEmail)} disabled={isProcessing} className="colorLink">{t('RESEND_CODE')}</Link>
       </Stack>
      <StyledButton data-testid="button" type='submit' variant="contained" fullWidth
        className="bgLink"
        disabled={!(code && password && confirmPassword && !(clientValidatedRequirements?.length > 0 && !requirementsMet) && !doPasswordsDiffer) || isProcessing}>
          {isProcessing ? <CircularProgress sx={{p:'4px'}}/> : t('CONTINUE')}
      </StyledButton>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        marginTop="24px"
       >
        <Link href="#" className="cancel-link" underline="always" onClick={() => onCancel(STATUS.SIGN_IN)}>{t('CANCEL')}</Link>
      </Stack> 
    </form>
  </div>
}
