import React from 'react';
import { Typography } from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Stack } from '@mui/system';
import {getPasswordPolicyMessage } from '../../utils/passwordValidate';

export default function PasswordPolicy({ validateRequirements }) {
  return <React.Fragment>
    <Typography color='#fff' x={{
      fontSize: '14px',
      lineHeight: '20px',
    }}>Your new password must:</Typography>
    {validateRequirements.map((policy) => {
      const color = policy.isValid ? '#7BFA11' : '#F77E51';
      const icon = policy.isValid ?
        <CheckCircleOutlineIcon sx={{ width: '1rem', margin: '0px 8px', color}}/> :
        <RadioButtonUncheckedIcon sx={{ width: '1rem', margin: '0px 8px', color}} />;
        return (
          <Stack direction="row" alignItems='center' key={policy.name}>
              {icon}
            <Typography color='#fff' sx={{
              fontSize: '14px',
              lineHeight: '20px',
            }}>{getPasswordPolicyMessage(policy)}</Typography>
            </Stack>
        )
    })}
  </React.Fragment>
}
