import bigInt from 'big-integer';

export const RequiredProperties = {
  MAX_REPEATED_CHARACTERS: 'maxRepeatedCharacters',
  MIN_UNIQUE_CHARACTERS: 'minUniqueCharacters',
  LENGTH: 'length',
  MIN_CHARACTERS: 'minCharacters',
  MIN_LOWERCASE: 'abcdefghijklmnopqrstuvwxyz',
  MIN_UPPERCASE: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
  MIN_NUMERIC: '0123456789',
  MIN_SPECIAL: '~!@#$%^&*()-_=+[]{}|;:,.<>/?',
  MIN_COMPLEXITY: 'minComplexity',
};

export const defaultPasswordPolicy = {
  "name": "Standard",
  "excludesProfileData": false,
  "notSimilarToCurrent": true,
  "excludesCommonlyUsed": true,
  "length": {
    "min": 8,
    "max": 255
  },
  "minCharacters": {
    "ABCDEFGHIJKLMNOPQRSTUVWXYZ": 1,
    "abcdefghijklmnopqrstuvwxyz": 1,
    "0123456789": 1,
    "~!@#$%^&*()-_=+[]{}|;:,.<>/?": 1,
  },
  "history": {
    "count": 4,
    "retentionDays": 365
  }
};

const minCharCheck = (password, charSet, min) => {
  let count = 0;
  for (const char of password) {
    if (charSet.includes(char)) {
      count++;
    }
  }
  return count >= min;
};

export const validate = (passwordPolicy, policyCriterion, password) => {
  switch (policyCriterion) {
    case RequiredProperties.MAX_REPEATED_CHARACTERS: {
      const maxRepeatedCharCount = passwordPolicy[policyCriterion];
      if (password.length < maxRepeatedCharCount) {
        return false;
      }

      for (let i = 0; i < password.length; i += 1) {
        const currentChar = password[i];
        let currentCharCount = 1;
        for (let j = i + 1; j < password.length; j += 1) {
          if (password[j] !== currentChar) {
            break;
          }

          currentCharCount += 1;

          if (currentCharCount > maxRepeatedCharCount) {
            return false;
          }
        }
      }

      return true;
    }
    case RequiredProperties.MIN_UNIQUE_CHARACTERS: {
      const minUniqueCharCount = passwordPolicy[policyCriterion];
      const count = new Set();
      for (const char of password) {
        count.add(char);
      };
      return count.size >= minUniqueCharCount;
    }
    case RequiredProperties.LENGTH: {
      const lengthValidationRequirements = passwordPolicy[policyCriterion];
      return (
        (password.length >= lengthValidationRequirements.min) &&
        (password.length <= lengthValidationRequirements.max)
      );
    }
    case RequiredProperties.MIN_NUMERIC: {
      return minCharCheck(
        password,
        RequiredProperties.MIN_NUMERIC,
        passwordPolicy[RequiredProperties.MIN_CHARACTERS][RequiredProperties.MIN_NUMERIC]
      );
    }
    case RequiredProperties.MIN_LOWERCASE: {
      return minCharCheck(
        password,
        RequiredProperties.MIN_LOWERCASE,
        passwordPolicy[RequiredProperties.MIN_CHARACTERS][RequiredProperties.MIN_LOWERCASE]
      );
    }
    case RequiredProperties.MIN_UPPERCASE: {
      return minCharCheck(
        password,
        RequiredProperties.MIN_UPPERCASE,
        passwordPolicy[RequiredProperties.MIN_CHARACTERS][RequiredProperties.MIN_UPPERCASE]
      );
    }
    case RequiredProperties.MIN_SPECIAL: {
      return minCharCheck(
        password,
        RequiredProperties.MIN_SPECIAL,
        passwordPolicy[RequiredProperties.MIN_CHARACTERS][RequiredProperties.MIN_SPECIAL]
      );
    }
    case RequiredProperties.MIN_COMPLEXITY: {
      const guessesPerSecond = 100000000000;
      const secondsInSevenDays = 604800;
      const minComplexity = bigInt(guessesPerSecond).times(secondsInSevenDays);
      if (password.length === 0) {
        return 0;
      }

      // Determine the search space depth for the password.  If the password
      // contains any characters from outside the four defined character sets
      // (lowercase ASCII letters, uppercase ASCII letters, ASCII digits, and
      // ASCII symbols), then the depth is automatically 256.  Otherwise, the
      // depth is the num of the number of characters in each set contained in the
      // password.
      let hasLowerLetter = false;
      let hasUpperLetter = false;
      let hasDigit = false;
      let hasSymbol = false;
      let hasOther = false;
      for (const char of password) {
        if (char >= 'a' && char <= 'z') {
          hasLowerLetter = true;
        } else if (char >= 'A' && char <= 'Z') {
          hasUpperLetter = true;
        } else if (char >= '0' && char <= '9') {
          hasDigit = true;
        } else if (char >= ' ' && char <= '~') {
          hasSymbol = true;
        } else {
          hasOther = true;
          return false;
        }

        return true;
      };

      // Determine the search space depth for the password.  If the password
      // contains any characters from outside the four defined character sets
      // (lowercase ASCII letters, uppercase ASCII letters, ASCII digits, and
      // ASCII symbols), then the depth is automatically 256.  Otherwise, the
      // depth is the num of the number of characters in each set contained in the
      // password.
      let searchSpaceDepth = 0;
      if (hasOther) {
        searchSpaceDepth = 256;
      } else {
        if (hasLowerLetter) {
          searchSpaceDepth += 26;
        }

        if (hasUpperLetter) {
          searchSpaceDepth += 26;
        }

        if (hasDigit) {
          searchSpaceDepth += 10;
        }

        if (hasSymbol) {
          searchSpaceDepth += 33;
        }
      }

      let searchSpaceSize = bigInt(0);
      for (let i = 1; i <= password.length; i += 1) {
        const guessesForLength = bigInt(searchSpaceDepth).pow(i);
        searchSpaceSize = searchSpaceSize.add(guessesForLength);
      }

      return searchSpaceSize.compare(minComplexity) > -1; // -1 means searchSpaceSize < minComplexity, 0 means =, 1 means >
    }
    default: {
      return false;
    }
  }
};

export const passwordRequirementsValidator = (passwordPolicy, newPassword) => {
  const clientValidatedRequirements = Object.keys(passwordPolicy).filter(
    (policy) => policy === RequiredProperties.MAX_REPEATED_CHARACTERS ||
      policy === RequiredProperties.MIN_UNIQUE_CHARACTERS ||
      policy === RequiredProperties.MIN_COMPLEXITY ||
      policy === RequiredProperties.LENGTH);

  // special handling to flatten minChars so each can be validated separately
  const minCharReqs = passwordPolicy[RequiredProperties.MIN_CHARACTERS];
  Object.keys(minCharReqs).forEach((value) => clientValidatedRequirements.push(`${value}`));

  const errors = clientValidatedRequirements.map((policyName) => ({
    name: policyName,
    isValid: validate(passwordPolicy, policyName, newPassword),
  }));

  return errors;
};

export const getServerValidatedRequirementMessage = (failedReq, passwordPolicy, t) => {
  switch (failedReq) {
    case 'history': {
      return t('PASSWORD_CANNOT_BE_SAME_AS_YOUR_LAST_4_USED_PASSWORDS');//codes.PASSWORD_CANNOT_BE_SAME_AS_YOUR_LAST_4_USED_PASSWORDS;
    }
    case 'excludesProfileData':
      return t('PASSWORD_CANNOT_CONTAIN_INFO_IN_PROFILE');//codes.PASSWORD_CANNOT_CONTAIN_INFO_IN_PROFILE;
    case 'notSimilarToCurrent':
      return t('PASSWORD_CANNOT_BE_SIMILAR_CURRENT_PASSWORD');//codes.PASSWORD_CANNOT_BE_SIMILAR_CURRENT_PASSWORD;
    case 'excludesCommonlyUsed':
      return t('PASSWORD_MUST_NOT_BE_COMMON_USED');//codes.PASSWORD_MUST_NOT_BE_COMMON_USED;
    case 'minComplexity':
    default:
      return t('PASSWORD_POLICY');//codes.PASSWORD_POLICY;
  }
};

export const getPasswordPolicyMessage = (policy, passwordPolicy = defaultPasswordPolicy) => {
  if (!passwordPolicy) {
    return {};
  }

  let requirement;
  if (policy.name === RequiredProperties.MIN_SPECIAL) {
    requirement = passwordPolicy.minCharacters[RequiredProperties.MIN_SPECIAL];
    return generatePasswordPolicyMessage(requirement)?.minSpecial;
  } else if (policy.name === RequiredProperties.MIN_LOWERCASE) {
    requirement = passwordPolicy.minCharacters[RequiredProperties.MIN_LOWERCASE];
    return generatePasswordPolicyMessage(requirement)?.minLower;
  } else if (policy.name === RequiredProperties.MIN_UPPERCASE) {
    requirement = passwordPolicy.minCharacters[RequiredProperties.MIN_UPPERCASE];
    return generatePasswordPolicyMessage(requirement)?.minUpper;
  } else if (policy.name === RequiredProperties.MIN_NUMERIC) {
    requirement = passwordPolicy.minCharacters[RequiredProperties.MIN_NUMERIC];
    return generatePasswordPolicyMessage(requirement)?.minNumeric;
  }

  return generatePasswordPolicyMessage(passwordPolicy[policy.name])[policy.name];
}
export const generatePasswordPolicyMessage = (value) => ({
  maxRepeatedCharacters: `No more than ${value} repeated character${value > 1 ? 's' : ''}`,
  minUniqueCharacters: `${value} unique character${value > 1 ? 's' : ''}`,
  length: `Be at least ${value.min} characters long`,
  minNumeric: `Contain at least ${value} number`,
  minLower: `Contain at least ${value} lower letter`,
  minUpper: `Contain at least ${value} uppercase letter`,
  minSpecial: `Contain at least ${value} special character`,
  minComplexity: 'Must be a strong password',
});

export const getPasswordPolicyList = <div>
  <ul className="bullet-points"> Your new password must:
    <li>Be atleast 8 characters long</li>
    <li>Contain at least 1 uppercase letter</li>
    <li>Contain at least 1 number</li>
    <li>Contain at least 1 special character</li>
  </ul>
</div>;
